import { ShopRecord, ShopState } from './shop.state';
import * as actions from './shop.actions';
import { ShopSettingsModel } from './models/shop-settings.model';
import { MenuModel } from './models/menu.model';
import { OrderStatusModel } from './models/order-status.model';
import { LanguageModel } from './models/language.model';
import { BrandModel } from './models/brand.model';
import { ErrorItemModel } from './models/error-item.model';

export const initialState: ShopState = (new ShopRecord() as unknown) as ShopState;

export function reducer(state = initialState, { type, payload }: any): ShopState {
    if (!type) {
        return state;
    }

    switch (type) {
        case actions.ActionTypes.ADD_SHOP: {
            return Object.assign({}, state, {
                addShopLoading: true,
                addShopLoaded: false,
                addShopFail: false,
                addShopData: null
            });
        }
        case actions.ActionTypes.ADD_SHOP_SUCCESS: {
            // console.log(payload);
            const settings = payload && payload.data ? new ShopSettingsModel(payload.data) : null;
            return Object.assign({}, state, {
                addShopLoading: false,
                addShopLoaded: true,
                addShopFail: false,
                addShopData: settings,
                currentShopName: settings && settings.idshopname ? settings.idshopname : null,
                getShopSettingsData: settings,
            });
        }
        case actions.ActionTypes.ADD_SHOP_FAIL: {
            return Object.assign({}, state, {
                addShopLoading: false,
                addShopLoaded: false,
                addShopFail: true,
                addShopData: null
            });
        }
        case actions.ActionTypes.CLEAR_ADD_SHOP_STATE: {
            return Object.assign({}, state, {
                addShopLoading: false,
                addShopLoaded: false,
                addShopFail: false,
                addShopData: null
            });
        }

        case actions.ActionTypes.GET_SHOP_SETTINGS: {
            return Object.assign({}, state, {
                getShopSettingsLoading: true,
                getShopSettingsLoaded: false,
                getShopSettingsFail: false,
                getShopSettingsData: null,
                currentShopName: null
            });
        }
        case actions.ActionTypes.GET_SHOP_SETTINGS_SUCCESS: {
            // console.log(payload);
            const settings = payload && payload.data ? new ShopSettingsModel(payload.data) : null;
            return Object.assign({}, state, {
                getShopSettingsLoading: false,
                getShopSettingsLoaded: true,
                getShopSettingsFail: false,
                getShopSettingsData: settings,
                currentShopName: settings && settings.idshopname ? settings.idshopname : null
            });
        }
        case actions.ActionTypes.GET_SHOP_SETTINGS_FAIL: {
            return Object.assign({}, state, {
                getShopSettingsLoading: false,
                getShopSettingsLoaded: false,
                getShopSettingsFail: true,
                getShopSettingsData: null,
                currentShopName: null
            });
        }
        case actions.ActionTypes.CLEAR_GET_SHOP_SETTINGS_STATE: {
            return Object.assign({}, state, {
                getShopSettingsLoading: false,
                getShopSettingsLoaded: false,
                getShopSettingsFail: false,
                getShopSettingsData: null,
                currentShopName: null
            });
        }

        case actions.ActionTypes.GET_MENU_ITEM: {
            return Object.assign({}, state, {
                getMenuItemLoading: true,
                getMenuItemLoaded: false,
                getMenuItemFail: false,
                getMenuItemData: null,
            });
        }
        case actions.ActionTypes.GET_MENU_ITEM_SUCCESS: {
            // console.log(payload);
            return Object.assign({}, state, {
                getMenuItemLoading: false,
                getMenuItemLoaded: true,
                getMenuItemFail: false,
                getMenuItemData: payload.data && payload.data.menu ? new MenuModel(payload.data.menu) : new MenuModel(null),
            });
        }
        case actions.ActionTypes.GET_MENU_ITEM_FAIL: {
            return Object.assign({}, state, {
                getMenuItemLoading: false,
                getMenuItemLoaded: false,
                getMenuItemFail: true,
                getMenuItemData: null,
            });
        }

        case actions.ActionTypes.UPDATE_MENU_ITEM: {
            return Object.assign({}, state, {
                updateMenuItemLoading: true,
                updateMenuItemLoaded: false,
                updateMenuItemFail: false,
                updateMenuItemData: null,
            });
        }
        case actions.ActionTypes.UPDATE_MENU_ITEM_SUCCESS: {
            // console.log(payload);
            return Object.assign({}, state, {
                updateMenuItemLoading: false,
                updateMenuItemLoaded: true,
                updateMenuItemFail: false,
                updateMenuItemData: payload,
            });
        }
        case actions.ActionTypes.UPDATE_MENU_ITEM_FAIL: {
            return Object.assign({}, state, {
                updateMenuItemLoading: false,
                updateMenuItemLoaded: false,
                updateMenuItemFail: true,
                updateMenuItemData: null,
            });
        }
        case actions.ActionTypes.CLEAR_UPDATE_MENU_ITEM: {
            return Object.assign({}, state, {
                updateMenuItemLoading: false,
                updateMenuItemLoaded: false,
                updateMenuItemFail: false,
                updateMenuItemData: null,
            });
        }

        case actions.ActionTypes.GET_ORDER_STATUS_LIST: {
            return Object.assign({}, state, {
                getOrderStatusListLoading: true,
                getOrderStatusListLoaded: false,
                getOrderStatusListFail: false,
                getOrderStatusListData: null
            });
        }
        case actions.ActionTypes.GET_ORDER_STATUS_LIST_SUCCESS: {
            let list = [];
            if (payload && payload.success && payload.data && payload.data.length) {
                list = payload.data.map((el: any) => new OrderStatusModel(el));
            }
            return Object.assign({}, state, {
                getOrderStatusListLoading: false,
                getOrderStatusListLoaded: true,
                getOrderStatusListFail: false,
                getOrderStatusListData: list
            });
        }
        case actions.ActionTypes.GET_ORDER_STATUS_LIST_FAIL: {
            return Object.assign({}, state, {
                getOrderStatusListLoading: false,
                getOrderStatusListLoaded: false,
                getOrderStatusListFail: true,
                getOrderStatusListData: null
            });
        }
        case actions.ActionTypes.CLEAR_GET_ORDER_STATUS_LIST_STATE: {
            return Object.assign({}, state, {
                getOrderStatusListLoading: false,
                getOrderStatusListLoaded: false,
                getOrderStatusListFail: false,
                getOrderStatusListData: null
            });
        }

        case actions.ActionTypes.GET_SHOP_LANG_LIST: {
            return Object.assign({}, state, {
                getShopLangListLoading: true,
                getShopLangListLoaded: false,
                getShopLangListFail: false,
                getShopLangListData: null,
            })
        }
        case actions.ActionTypes.GET_SHOP_LANG_LIST_SUCCESS: {
            let langlist = [];
            if (payload && payload.data && !!payload.data.length && payload.success) {
                langlist = payload.data.map((el: any) => new LanguageModel(el));
            }
            return Object.assign({}, state, {
                getShopLangListLoading: false,
                getShopLangListLoaded: true,
                getShopLangListFail: false,
                getShopLangListData: langlist,
            })
        }
        case actions.ActionTypes.GET_SHOP_LANG_LIST_FAIL: {
            return Object.assign({}, state, {
                getShopLangListLoading: false,
                getShopLangListLoaded: false,
                getShopLangListFail: true,
                getShopLangListData: null,
            })
        }
        case actions.ActionTypes.CLEAR_GET_SHOP_LANG_LIST_STATE: {
            return Object.assign({}, state, {
                getShopLangListLoading: false,
                getShopLangListLoaded: false,
                getShopLangListFail: false,
                getShopLangListData: null,
            })
        }

        case actions.ActionTypes.GET_BRANDS_LIST: {
            return Object.assign({}, state, {
                getBrandsListLoading: true,
                getBrandsListLoaded: false,
                getBrandsListFail: false,
                getBrandsListData: null
            });
        }
        case actions.ActionTypes.GET_BRANDS_LIST_SUCCESS: {
            let brands: BrandModel[] = [];
            if (payload && !!payload.success && payload.res && !!payload.res.length) {
                brands = payload.res.map((el: any) => new BrandModel(el));
            }
            return Object.assign({}, state, {
                getBrandsListLoading: false,
                getBrandsListLoaded: true,
                getBrandsListFail: false,
                getBrandsListData: brands
            });
        }
        case actions.ActionTypes.GET_BRANDS_LIST_FAIL: {
            return Object.assign({}, state, {
                getBrandsListLoading: false,
                getBrandsListLoaded: false,
                getBrandsListFail: true,
                getBrandsListData: null
            });
        }
        case actions.ActionTypes.CLEAR_GET_BRANDS_LIST_STATE: {
            return Object.assign({}, state, {
                getBrandsListLoading: false,
                getBrandsListLoaded: false,
                getBrandsListFail: false,
                getBrandsListData: null
            });
        }

        case actions.ActionTypes.CREATE_PAYPAL_HOOK: {
            return Object.assign({}, state, {
                createPaypalHookLoading: true,
                createPaypalHookLoaded: false,
                createPaypalHookFail: false,
                createPaypalHookData: null,
            });
        }
        case actions.ActionTypes.CREATE_PAYPAL_HOOK_SUCCESS: {
            return Object.assign({}, state, {
                createPaypalHookLoading: false,
                createPaypalHookLoaded: true,
                createPaypalHookFail: false,
                createPaypalHookData: payload,
            });
        }
        case actions.ActionTypes.CREATE_PAYPAL_HOOK_FAIL: {
            return Object.assign({}, state, {
                createPaypalHookLoading: false,
                createPaypalHookLoaded: false,
                createPaypalHookFail: true,
                createPaypalHookData: null,
            });
        }
        case actions.ActionTypes.CLEAR_CREATE_PAYPAL_HOOK_STATE: {
            return Object.assign({}, state, {
                createPaypalHookLoading: false,
                createPaypalHookLoaded: false,
                createPaypalHookFail: false,
                createPaypalHookData: null,
            });
        }

        case actions.ActionTypes.CREATE_STRIPE_HOOK: {
            return Object.assign({}, state, {
                createStripeHookLoading: true,
                createStripeHookLoaded: false,
                createStripeHookFail: false,
                createStripeHookData: null
            });
        }
        case actions.ActionTypes.CREATE_STRIPE_HOOK_SUCCESS: {
            return Object.assign({}, state, {
                createStripeHookLoading: false,
                createStripeHookLoaded: true,
                createStripeHookFail: false,
                createStripeHookData: payload
            });
        }
        case actions.ActionTypes.CREATE_STRIPE_HOOK_FAIL: {
            return Object.assign({}, state, {
                createStripeHookLoading: false,
                createStripeHookLoaded: false,
                createStripeHookFail: true,
                createStripeHookData: null
            });
        }
        case actions.ActionTypes.CLEAR_CREATE_STRIPE_HOOK_STATE: {
            return Object.assign({}, state, {
                createStripeHookLoading: false,
                createStripeHookLoaded: false,
                createStripeHookFail: false,
                createStripeHookData: null
            });
        }

        case actions.ActionTypes.GET_SHOP_ERRORS_LIST: {
            return Object.assign({}, state, {
                getShopErrorListLoading: true,
                getShopErrorListLoaded: false,
                getShopErrorListFail: false,
                getShopErrorListData: null,
            });
        }
        case actions.ActionTypes.GET_SHOP_ERRORS_LIST_FAIL: {
            return Object.assign({}, state, {
                getShopErrorListLoading: false,
                getShopErrorListLoaded: false,
                getShopErrorListFail: true,
                getShopErrorListData: null,
            });
        }
        case actions.ActionTypes.GET_SHOP_ERRORS_LIST_SUCCESS: {
            let list: ErrorItemModel[] = [];
            if (payload && payload.success && payload.data && !!payload.data.length) {
                list = payload.data.map((el: any) => new ErrorItemModel(el));
            }

            return Object.assign({}, state, {
                getShopErrorListLoading: false,
                getShopErrorListLoaded: true,
                getShopErrorListFail: false,
                getShopErrorListData: list,
            });
        }
        case actions.ActionTypes.CLEAR_GET_SHOP_ERRORS_LIST_STATE: {
            return Object.assign({}, state, {
                getShopErrorListLoading: false,
                getShopErrorListLoaded: false,
                getShopErrorListFail: false,
                getShopErrorListData: null,
            });
        }

        case actions.ActionTypes.UPDATE_ERROR_STATUS: {
            return Object.assign({}, state, {
                updateErrorStatusLoading: true,
                updateErrorStatusLoaded: false,
                updateErrorStatusFail: false,
                updateErrorStatusData: null
            });
        }
        case actions.ActionTypes.UPDATE_ERROR_STATUS_SUCCESS: {
            return Object.assign({}, state, {
                updateErrorStatusLoading: false,
                updateErrorStatusLoaded: true,
                updateErrorStatusFail: false,
                updateErrorStatusData: payload
            });
        }
        case actions.ActionTypes.UPDATE_ERROR_STATUS_FAIL: {
            return Object.assign({}, state, {
                updateErrorStatusLoading: false,
                updateErrorStatusLoaded: false,
                updateErrorStatusFail: true,
                updateErrorStatusData: null
            });
        }
        case actions.ActionTypes.CLEAR_UPDATE_ERROR_STATUS_STATE: {
            return Object.assign({}, state, {
                updateErrorStatusLoading: false,
                updateErrorStatusLoaded: false,
                updateErrorStatusFail: false,
                updateErrorStatusData: null
            });
        }


        default: {
            return { ...state };
        }
    }
}

export const addShopLoading = (state: ShopState) => state.addShopLoading;
export const addShopLoaded = (state: ShopState) => state.addShopLoaded;
export const addShopFail = (state: ShopState) => state.addShopFail;
export const addShopData = (state: ShopState) => state.addShopData;

export const getShopSettingsLoading = (state: ShopState) => state.getShopSettingsLoading;
export const getShopSettingsLoaded = (state: ShopState) => state.getShopSettingsLoaded;
export const getShopSettingsFail = (state: ShopState) => state.getShopSettingsFail;
export const getShopSettingsData = (state: ShopState) => state.getShopSettingsData;

export const currentShopName = (state: ShopState) => state.currentShopName;

export const getMenuItemLoading = (state: ShopState) => state.getMenuItemLoading;
export const getMenuItemLoaded = (state: ShopState) => state.getMenuItemLoaded;
export const getMenuItemFail = (state: ShopState) => state.getMenuItemFail;
export const getMenuItemData = (state: ShopState) => state.getMenuItemData;

export const updateMenuItemLoading = (state: ShopState) => state.updateMenuItemLoading;
export const updateMenuItemLoaded = (state: ShopState) => state.updateMenuItemLoaded;
export const updateMenuItemFail = (state: ShopState) => state.updateMenuItemFail;
export const updateMenuItemData = (state: ShopState) => state.updateMenuItemData;

export const getOrderStatusListLoading = (state: ShopState) => state.getOrderStatusListLoading;
export const getOrderStatusListLoaded = (state: ShopState) => state.getOrderStatusListLoaded;
export const getOrderStatusListFail = (state: ShopState) => state.getOrderStatusListFail;
export const getOrderStatusListData = (state: ShopState) => state.getOrderStatusListData;

export const getShopLangListLoading = (state: ShopState) => state.getShopLangListLoading;
export const getShopLangListLoaded = (state: ShopState) => state.getShopLangListLoaded;
export const getShopLangListFail = (state: ShopState) => state.getShopLangListFail;
export const getShopLangListData = (state: ShopState) => state.getShopLangListData;

export const getBrandsListLoading = (state: ShopState) => state.getBrandsListLoading;
export const getBrandsListLoaded = (state: ShopState) => state.getBrandsListLoaded;
export const getBrandsListFail = (state: ShopState) => state.getBrandsListFail;
export const getBrandsListData = (state: ShopState) => state.getBrandsListData;

export const createPaypalHookLoading = (state: ShopState) => state.createPaypalHookLoading;
export const createPaypalHookLoaded = (state: ShopState) => state.createPaypalHookLoaded;
export const createPaypalHookFail = (state: ShopState) => state.createPaypalHookFail;
export const createPaypalHookData = (state: ShopState) => state.createPaypalHookData;

export const createStripeHookLoading = (state: ShopState) => state.createStripeHookLoading;
export const createStripeHookLoaded = (state: ShopState) => state.createStripeHookLoaded;
export const createStripeHookFail = (state: ShopState) => state.createStripeHookFail;
export const createStripeHookData = (state: ShopState) => state.createStripeHookData;

export const getShopErrorListLoading = (state: ShopState) => state.getShopErrorListLoading;
export const getShopErrorListLoaded= (state: ShopState) => state.getShopErrorListLoaded;
export const getShopErrorListFail = (state: ShopState) => state.getShopErrorListFail;
export const getShopErrorListData = (state: ShopState) => state.getShopErrorListData;

export const updateErrorStatusLoading = (state: ShopState) => state.updateErrorStatusLoading;
export const updateErrorStatusLoaded = (state: ShopState) => state.updateErrorStatusLoaded;
export const updateErrorStatusFail = (state: ShopState) => state.updateErrorStatusFail;
export const updateErrorStatusData = (state: ShopState) => state.updateErrorStatusData;
