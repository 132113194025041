import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, switchMap, retry } from 'rxjs/operators';
import * as actions from './root.actions';
import { RootService } from './root.service';

@Injectable()
export class RootEffect {
    constructor(
        private actions$: Actions,
        private service: RootService
    ) { }

    getUsersList$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_USERS_LIST),
        map((action: actions.GetUsersListAction) => action.payload),
        switchMap(state => {
            return this.service.getUsersList(state).pipe(
                map(data => new actions.GetUsersListSuccessAction(data)),
                catchError(err => of(new actions.GetUsersListFailAction(err)))
            );
        })
    ));

    createUser$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.CREATE_USER),
        map((action: actions.CreateUserAction) => action.payload),
        switchMap(state => {
            return this.service.createUser(state).pipe(
                map(data => new actions.CreateUserSuccessAction(data)),
                catchError(err => of(new actions.CreateUserFailAction(err)))
            );
        })
    ));

    getClustersList$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_CLUSTERS_LIST),
        map((action: actions.GetClustersListAction) => action.payload),
        switchMap(state => {
            return this.service.getClustersList(state).pipe(
                map(data => new actions.GetClustersListSuccessAction(data)),
                catchError(err => of(new actions.GetClustersListFailAction(err)))
            );
        })
    ));

    getShopCluster$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_SHOP_CLUSTER),
        map((action: actions.GetShopClusterAction) => action.payload),
        switchMap(state => {
            return this.service.getShopCluster(state).pipe(
                map(data => new actions.GetShopClusterSuccessAction(data)),
                catchError(err => of(new actions.GetShopClusterFailAction(err)))
            );
        })
    ));

    clusterSave$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.CLUSTER_SAVE),
        map((action: actions.ClusterSaveAction) => action.payload),
        switchMap(state => {
            return this.service.clusterSave(state).pipe(
                map(data => new actions.ClusterSaveSuccessAction(data)),
                catchError(err => of(new actions.ClusterSaveFailAction(err)))
            );
        })
    ));

    userSave$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.UPDATE_USER),
        map((action: actions.UpdateUserAction) => action.payload),
        switchMap(state => {
            return this.service.updateUser(state).pipe(
                map(data => new actions.UpdateUserSuccessAction(data)),
                catchError(err => of(new actions.UpdateUserFailAction(err)))
            );
        })
    ));

    wipeData$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.WIPE_DATA),
        map((action: actions.WipeDataAction) => action.payload),
        switchMap(state => {
            const params = state.params;
            const cluster_id = state.cluster_id;
            return this.service.wipeData(cluster_id, params).pipe(
                map(data => new actions.WipeDataSuccessAction(data)),
                catchError(err => of(new actions.WipeDataFailAction(err)))
            );
        })
    ));
}

