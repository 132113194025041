import { Component, Inject } from "@angular/core";
import { FormControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

class YoutubeLinkValidators {
    static validUrl: ValidatorFn = (control: FormControl): ValidationErrors | null => {
        if (control.value) {
            if (control.value.indexOf('://www.youtube.com/') > -1) {
                return null;
            }
            return { required: true };
        }
        return { required: true };
    }
}


@Component({
    templateUrl: './add-video-dialog.component.html',
    styleUrls: ['./add-video-dialog.component.scss']
})
export class AddVideoDialogComponent {
    url: FormControl = new FormControl('', [Validators.required, YoutubeLinkValidators.validUrl]);

    constructor(
        private dialogRef: MatDialogRef<AddVideoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: {
            link: string;
        }
    ) {
        if (this.data?.link) {
            this.url.patchValue(this.data.link);
        }
    }

    submit(): void {
        if (this.url.valid) {
            this.dialogRef.close(this.url.value);
        } else {
            console.error('form invalid');
        }
    }
}