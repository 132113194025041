import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserModel } from "app/core/account/models/registerUser.model";
import { ClusterModel } from "app/core/root/models/cluster.model";
import { RootSandbox } from "app/core/root/root.sandbox";
import { ACCOUNT_VERSION, USER_ROLES } from "app/core/shared/helpers/constants.enum";
import { Subscription } from "rxjs";
import { SnackService } from "../snack.service";

@Component({
    templateUrl: './create-user.dialog.html',
    styleUrls: ['./create-user.dialog.scss'],
})
export class CreateUserDialogComponent implements OnInit, OnDestroy, AfterViewInit {
    private subs: Subscription[] = [];
    public USER_ROLES = USER_ROLES;
    public ACCOUNT_VERSION = ACCOUNT_VERSION;

    form: FormGroup;
    email: FormControl = new FormControl('', Validators.compose([Validators.required, Validators.email]));
    password: FormControl = new FormControl('');
    name: FormControl = new FormControl('', Validators.required);
    clusterId: FormControl = new FormControl('');
    role: FormControl = new FormControl(USER_ROLES.user, Validators.required);
    plan: FormControl = new FormControl(ACCOUNT_VERSION.standart, Validators.required);

    clustersList: ClusterModel[] = [];

    constructor(
        private dialogRef: MatDialogRef<CreateUserDialogComponent>,
        private fb: FormBuilder,
        public rootSandbox: RootSandbox,
        private cd: ChangeDetectorRef,
        private message: SnackService,
        @Inject(MAT_DIALOG_DATA) public data: UserModel,
    ) { }

    ngOnInit(): void {
        this.form = this.fb.group({
            email: this.email,
            password: this.password,
            name: this.name,
            clusterId: this.clusterId,
            role: this.role,
            plan: this.plan
        });
    }
    
    ngAfterViewInit(): void {
        this.rootSandbox.doGetClustersList({});
        this.rootSandbox.doClearUpdateUserState();
        this.rootSandbox.doClearCreateUser();

        if (this.data) {
            this.password.addValidators(Validators.compose([Validators.required, Validators.minLength(6)]));
            this.email.patchValue(this.data.email);
            this.name.patchValue(this.data.name);

            this.clusterId.patchValue(this.data.cluster_id);
            this.role.patchValue(this.data.role);

            this.plan.patchValue(this.data.plan);
            // console.log(this.data);
        }

        this.subs.push(this.rootSandbox.getClustersListData$.subscribe((res: ClusterModel[]) => {
            if (res) {
                this.clustersList = res.filter(el => el.active);
                // console.log(this.clustersList);
            } else {
                this.clustersList = [];
            }
            this.cd.markForCheck();
        }));
        this.subs.push(this.rootSandbox.createUserData$.subscribe(res => {
            if (res) {
                // console.log(res);
                this.dialogRef.close(res);
            }
        }));
        this.subs.push(this.rootSandbox.createUserErrMess$.subscribe(res => {
            if (res) {
                this.message.log(res);
            }
        }));
        this.subs.push(this.rootSandbox.updateUserData$.subscribe(res => {
            if (res) {
                // console.log(res);
                this.dialogRef.close(res);
            }
        }));
    }

    

    confirm(): void {
        if (this.form.valid) {
            const params: any = {
                email: this.email.value,
                name: this.name.value,
                role: this.role.value,
                cluster_id: this.clusterId.value,
                plan: this.plan.value
            }
            if (this.password.value) {
                params.password = this.password.value;
            }

            console.log(params);

            if (this.data) {
                params.id = this.data.id;
                this.rootSandbox.doUpdateUser(params);
            } else {
                this.rootSandbox.doCreateUser(params);
            }
        } else {
            this.message.log('Invalid form');
        }
        // this.dialogRef.close(true);
    }

    cancel(): void {
        this.dialogRef.close(false);
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}


