import { createSelector } from 'reselect';

import * as reducer from './common.reducer';
import { AppState } from '../state.interface';

const getState = (state: AppState) => state.common;

export const getPaymentListLoading = createSelector(getState, reducer.getPaymentListLoading);
export const getPaymentListLoaded = createSelector(getState, reducer.getPaymentListLoaded);
export const getPaymentListFail = createSelector(getState, reducer.getPaymentListFail);
export const getPaymentListData = createSelector(getState, reducer.getPaymentListData);

export const getCategoryListLoading = createSelector(getState, reducer.getCategoryListLoading);
export const getCategoryListLoaded = createSelector(getState, reducer.getCategoryListLoaded);
export const getCategoryListFail = createSelector(getState, reducer.getCategoryListFail);
export const getCategoryListData = createSelector(getState, reducer.getCategoryListData);

export const getCategoryItemLoading = createSelector(getState, reducer.getCategoryItemLoading);
export const getCategoryItemLoaded = createSelector(getState, reducer.getCategoryItemLoaded);
export const getCategoryItemFail = createSelector(getState, reducer.getCategoryItemFail);
export const getCategoryItemData = createSelector(getState, reducer.getCategoryItemData);

export const uploadFileLoading = createSelector(getState, reducer.uploadFileLoading);
export const uploadFileLoaded = createSelector(getState, reducer.uploadFileLoaded);
export const uploadFileFail = createSelector(getState, reducer.uploadFileFail);
export const uploadFileData = createSelector(getState, reducer.uploadFileData);
export const uploadFileErrMess = createSelector(getState, reducer.uploadFileErrMess);

export const updatePositionLoading = createSelector(getState, reducer.updatePositionLoading);
export const updatePositionLoaded = createSelector(getState, reducer.updatePositionLoaded);
export const updatePositionFail = createSelector(getState, reducer.updatePositionFail);

export const shopPagesLoading = createSelector(getState, reducer.shopPagesLoading);
export const shopPagesLoaded = createSelector(getState, reducer.shopPagesLoaded);
export const shopPagesFail = createSelector(getState, reducer.shopPagesFail);
export const shopPagesData = createSelector(getState, reducer.shopPagesData);

export const getProductsListLoading = createSelector(getState, reducer.getProductsListLoading);
export const getProductsListLoaded = createSelector(getState, reducer.getProductsListLoaded);
export const getProductsListFail = createSelector(getState, reducer.getProductsListFail);
export const getProductsListData = createSelector(getState, reducer.getProductsListData);

export const getProductItemLoading = createSelector(getState, reducer.getProductItemLoading);
export const getProductItemLoaded = createSelector(getState, reducer.getProductItemLoaded);
export const getProductItemFail = createSelector(getState, reducer.getProductItemFail);
export const getProductItemData = createSelector(getState, reducer.getProductItemData);

export const addNotificationLoading = createSelector(getState, reducer.addNotificationLoading);
export const addNotificationLoaded = createSelector(getState, reducer.addNotificationLoaded);
export const addNotificationFail = createSelector(getState, reducer.addNotificationFail);
export const addNotificationData = createSelector(getState, reducer.addNotificationData);

export const getCustomersListLoading = createSelector(getState, reducer.getCustomersListLoading);
export const getCustomersListLoaded = createSelector(getState, reducer.getCustomersListLoaded);
export const getCustomersListFail = createSelector(getState, reducer.getCustomersListFail);
export const getCustomersListData = createSelector(getState, reducer.getCustomersListData);

export const getOrderStatusSettingsLoading = createSelector(getState, reducer.getOrderStatusSettingsLoading);
export const getOrderStatusSettingsLoaded = createSelector(getState, reducer.getOrderStatusSettingsLoaded);
export const getOrderStatusSettingsFail = createSelector(getState, reducer.getOrderStatusSettingsFail);
export const getOrderStatusSettingsData = createSelector(getState, reducer.getOrderStatusSettingsData);

export const setOrderStatusSettingsLoading = createSelector(getState, reducer.setOrderStatusSettingsLoading);
export const setOrderStatusSettingsLoaded = createSelector(getState, reducer.setOrderStatusSettingsLoaded);
export const setOrderStatusSettingsFail = createSelector(getState, reducer.setOrderStatusSettingsFail);
export const setOrderStatusSettingsData = createSelector(getState, reducer.setOrderStatusSettingsData);
// 
export const getPrestashopProductsListData = createSelector(getState, reducer.getPrestashopProductsListData);
export const getPrestashopProductsListFailData = createSelector(getState, reducer.getPrestashopProductsListFailData);
export const getPrestashopProductsListLoading = createSelector(getState, reducer.getPrestashopProductsListLoading);
// 
export const videoUploadData = createSelector(getState, reducer.videoUploadData);
export const videoUploadFailData = createSelector(getState, reducer.videoUploadFailData);
export const videoUploadLoading = createSelector(getState, reducer.videoUploadLoading);
