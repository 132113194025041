// import { ACCOUNT_VERSION } from "src/core/helpers/constants.enum";

import { ACCOUNT_VERSION } from "app/core/shared/helpers/constants.enum";

export class RegisterUserModel {
    public CreatedAt: string;
    public DeletedAt: any;
    public id: number;
    public UpdatedAt: string;
    public email: string;
    public name: string;
    public password: string;
    public role: string;

    constructor(res: any) {
        this.CreatedAt = res.CreatedAt || '';
        this.DeletedAt = res.DeletedAt;
        this.id = res.ID;
        this.UpdatedAt = res.UpdatedAt || '';
        this.email = res.email || '';
        this.name = res.name || '';
        this.password = res.password;
        this.role = res.role || '';
    }
}


export class UserModel {
    public CreatedAt: string;
    public DeletedAt: any;
    public id: string;
    public UpdatedAt: string;
    public email: string;
    public name: string;
    public password: string;
    public role: string;

    public created_at: string;
    public cluster_id: string;

    public presta_key: string;

    public id_shopname: string;
    public plan: string;

    public status = 'online';
    public avatar = '';

    constructor(res: any) {
        this.CreatedAt = res.CreatedAt || '';
        this.DeletedAt = res.DeletedAt;
        this.id = res.id;
        this.UpdatedAt = res.UpdatedAt || '';
        this.email = res.email || '';
        this.name = res.name || '';
        this.password = res.password;
        this.role = res.role || '';

        this.created_at = res.created_at || '';
        this.cluster_id = res.cluster_id || '';

        this.presta_key = res.presta_key ? res.presta_key : '';

        this.id_shopname = res.id_shopname ? res.id_shopname : '';

        this.plan = res.plan ? res.plan : ACCOUNT_VERSION.standart;
    }
}
