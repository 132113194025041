import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import * as actions from './common.action';
// import * as store from '../state';
import { CommonService } from './common.service';
import { createSelector, Store } from '@ngrx/store';

@Injectable()
export class CommonEffect {
    constructor(
        private actions$: Actions,
        private commonService: CommonService,
        // protected appState$: Store<store.AppState>
    ) { }

    // paymentlist$ = createEffect(() => this.actions$.pipe(
    //     ofType(actions.ActionTypes.GET_PAYMENT_LIST),
    //     map((action: actions.GetPaymentListAction) => action.payload),
    //     switchMap(state => {
    //         return this.commonService.getPaymnetList().pipe(
    //             map(data => new actions.GetPaymentListSuccessAction(data)),
    //             catchError(err => of(new actions.GetPaymentListFailAction(err)))
    //         );
    //     })
    // ));

    getcategorylist$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_CATEGORY_LIST),
        map((action: actions.GetCategoryListAction) => action.payload),
        switchMap(state => {
            return this.commonService.getCategoryList(state).pipe(
                map(data => new actions.GetCategoryListSuccessAction(data)),
                catchError(err => of(new actions.GetCategoryListFailAction(err)))
            );
        })
    ));

    getcategoryitem$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_CATEGORY_ITEM),
        map((action: actions.GetCategoryItemAction) => action.payload),
        switchMap(state => {
            return this.commonService.getCategoryList(state).pipe(
                map(data => new actions.GetCategoryItemSuccessAction(data)),
                catchError(err => of(new actions.GetCategoryItemFailAction(err)))
            );
        })
    ));

    uploadFile$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.UPLOAD_FILE),
        map((action: actions.UploadFileAction) => action.payload),
        switchMap(state => {
            const file = state.file;
            const params = state.params;
            return this.commonService.uploadFile(file, params).pipe(
                map(data => new actions.UploadFileSuccessAction(data)),
                catchError(err => of(new actions.UploadFileFailAction(err)))
            );
        })
    ));

    updateposition$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.UPDATE_POSITION),
        map((action: actions.UpdatePositionAction) => action.payload),
        switchMap(state => {
            return this.commonService.updatePosition(state).pipe(
                map(data => new actions.UpdatePositionSuccessAction(data)),
                catchError(err => of(new actions.UpdatePositionFailAction(err)))
            );
        })
    ));

    getShopPages$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_SHOP_PAGES),
        map((action: actions.GetShopPagesAction) => action.payload),
        switchMap(state => {
            return this.commonService.getShopPages(state).pipe(
                map(data => new actions.GetShopPagesSuccessAction(data)),
                catchError(err => of(new actions.GetShopPagesFailAction(err)))
            );
        })
    ));

    getProductsList$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_PRODUCTS_LIST),
        map((action: actions.GetProductsListAction) => action.payload),
        switchMap(state => {
            return this.commonService.getProductsList(state).pipe(
                map(data => new actions.GetProductsListSuccessAction(data)),
                catchError(err => of(new actions.GetProductsListFailAction(err)))
            );
        })
    ));

    getProductsItem$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_PRODUCT_ITEM),
        map((action: actions.GetProductItemAction) => action.payload),
        switchMap(state => {
            return this.commonService.getProductsList(state).pipe(
                map(data => new actions.GetProductItemSuccessAction(data)),
                catchError(err => of(new actions.GetProductItemFailAction(err)))
            );
        })
    ));

    addNotification$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.ADD_NOTIFICATION),
        map((action: actions.AddNotificationAction) => action.payload),
        switchMap(state => {
            return this.commonService.addNotification(state).pipe(
                map(data => new actions.AddNotificationSuccessAction(data)),
                catchError(err => of(new actions.AddNotificationFailAction(err)))
            );
        })
    ));

    getCustomerList$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_CUSTOMERS_LIST),
        map((action: actions.GetCustomersListAction) => action.payload),
        switchMap(state => {
            return this.commonService.getCustomerList(state).pipe(
                map(data => new actions.GetCustomersListSuccessAction(data)),
                catchError(err => of(new actions.GetCustomersListFailAction(err)))
            );
        })
    ));

    getOrserStatusSettings$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_ORDER_STATUS_SETTINGS),
        map((action: actions.GetOrderStatusSettingsAction) => action.payload),
        switchMap(state => {
            return this.commonService.getOrderStatusSettings(state).pipe(
                map(data => new actions.GetOrderStatusSettingsSuccessAction(data)),
                catchError(err => of(new actions.GetOrderStatusSettingsFailAction(err)))
            );
        })
    ));

    setOrderStatusSettings$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.SET_ORDER_STATUS_SETTINGS),
        map((action: actions.SetOrderStatusSettingsAction) => action.payload),
        switchMap(state => {
            return this.commonService.setOrderStatusSettings(state).pipe(
                map(data => new actions.SetOrderStatusSettingsSuccessAction(data)),
                catchError(err => of(new actions.SetOrderStatusSettingsFailAction(err)))
            );
        })
    ));

    getprestashopproductslist$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_PRESTASHOP_PRODUCTS_LIST),
        map((action: actions.GetPrestashopProductsListAction) => action.payload),
        switchMap(state => {
            return this.commonService.getPrestashopProductsList(state.params).pipe(
                map(data => {
                    if (data.success) {
                        return new actions.GetPrestashopProductsListSuccessAction(data.data || [])
                    } else {
                        new actions.GetPrestashopProductsListFailAction(data)
                    }
                }),
                catchError(err => of(new actions.GetPrestashopProductsListFailAction(err)))
            );
        })
    ));

    uploadvideo$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.VIDEO_UPLOAD),
        map((action: actions.VideoUploadAction) => action.payload),
        switchMap(state => {
            return this.commonService.videoUpload(state.params).pipe(
                map(data => {
                    if (data.success) {
                        return new actions.VideoUploadSuccessAction(data.data || [])
                    } else {
                        new actions.VideoUploadFailAction(data)
                    }
                }),
                catchError(err => of(new actions.VideoUploadFailAction(err)))
            );
        })
    ));
}


