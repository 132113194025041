import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonSandbox } from 'app/core/common/common.sandbox';
import { CustomerModel } from 'app/core/common/models/customer.model';
import { ShopSandbox } from 'app/core/shop/shop.sandbox';
import { debounceTime, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
    templateUrl: './select-customer.dialog.html',
    styleUrls:['./select-customer.dialog.scss']
})
export class SelectCustomerDialogComponent implements OnInit, OnDestroy {
    private subs: Subscription[] = [];

    idShopname: string = null;
    customers: CustomerModel[] = [];

    keyword: FormControl = new FormControl('');

    constructor(
        private dialogRef: MatDialogRef<SelectCustomerDialogComponent>,
        private shopSandbox: ShopSandbox,
        private commonSandbox: CommonSandbox,
        private cd: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.subs.push(this.shopSandbox.currentShopName$.subscribe(shopName => {
            this.idShopname = shopName || '';

            this.fetchData();
        }));
        this.subs.push(this.commonSandbox.getCustomersListData$.subscribe(res => {
            // if (res) {
            //     console.log(res);
            // }
            this.customers = res ? res : [];
            this.cd.markForCheck();
        }));
        this.subs.push(this.keyword.valueChanges.pipe(debounceTime(400)).subscribe(res => {
            this.fetchData()
        }));
    }

    fetchData(): void {
        if (this.idShopname) {
            this.commonSandbox.doGetCustomerList({
                idShopname: this.idShopname,
                keyword: this.keyword.value || '',
                limit: 100
            })
        }
    }

    select(customer: CustomerModel): void {
        this.dialogRef.close(customer);
    }

    close(): void {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}

