<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-black print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="!isScreenSmall" *ngIf="navigation">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center h-20 p-6 pb-0">
            <!-- <h3 class="dark:hidden w-30">PrestApp</h3> -->

            <h3 class="hidden dark:flex w-30">
                <img src="assets/images/logo/logo-prestapp-15.png" style="display: block;">
                <!-- <ng-container *ngIf="currentPlan === 'pro'">
                    {{'E-commerceApp' | translate}}
                </ng-container>
                <ng-container *ngIf="currentPlan !== 'pro'">
                    {{'Prestapp' | translate}}
                </ng-container> -->
            </h3>
            <!-- Light version -->
            <!-- <img class="dark:hidden w-30" src="assets/images/logo/logo-text.svg" alt="Logo image"> -->
            <!-- Dark version -->
            <!-- <img class="hidden dark:flex w-30" src="assets/images/logo/logo-text-on-dark.svg" alt="Logo image"> -->
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <user [showAvatar]="false"></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div
        class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">PrestApp &copy; {{currentYear}}</span>
    </div>

</div>