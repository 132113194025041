import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AccountSandbox } from "app/core/account/account.sandbox";
import { USER_ROLES } from "app/core/shared/helpers/constants.enum";

@Injectable({providedIn: 'root'})
export class RootGuard implements CanActivate {
    private currentRole: string = null;

    constructor(
        private router: Router,
        private snackBar: MatSnackBar,
        private accountSandbox: AccountSandbox
    ) {
        this.accountSandbox.currentUserRole$.subscribe(role => {
            this.currentRole = role;
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        if (this.currentRole && (this.currentRole === USER_ROLES.admin || this.currentRole === USER_ROLES.root )) {
            return true;
        } else {
            this.snackBar.open(
                'Unauthorized!', 'Close',
                {
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    duration: 3000
                }
            );

            this.router.navigate(['/']);
            return false;
        }
    }
}