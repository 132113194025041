import { Action } from '@ngrx/store';
import { type } from '../shared/utility/utilityHelper';

export const ActionTypes = {
    CREATE_USER: type('CREATE_USER'),
    CREATE_USER_SUCCESS: type('CREATE_USER_SUCCESS'),
    CREATE_USER_FAIL: type('CREATE_USER_FAIL'),
    CLEAR_CREATE_USER_STATE: type('CLEAR_CREATE_USER_STATE'),

    GET_USERS_LIST: type('GET_USERS_LIST'),
    GET_USERS_LIST_SUCCESS: type('GET_USERS_LIST_SUCCESS'),
    GET_USERS_LIST_FAIL: type('GET_USERS_LIST_FAIL'),

    GET_CLUSTERS_LIST: type('GET_CLUSTERS_LIST'),
    GET_CLUSTERS_LIST_SUCCESS: type('GET_CLUSTERS_LIST_SUCCESS'),
    GET_CLUSTERS_LIST_FAIL: type('GET_CLUSTERS_LIST_FAIL'),

    GET_SHOP_CLUSTER: type('GET_SHOP_CLUSTER'),
    GET_SHOP_CLUSTER_SUCCESS: type('GET_SHOP_CLUSTER_SUCCESS'),
    GET_SHOP_CLUSTER_FAIL: type('GET_SHOP_CLUSTER_FAIL'),

    CLUSTER_SAVE: type('CLUSTER_SAVE'),
    CLUSTER_SAVE_SUCCESS: type('CLUSTER_SAVE_SUCCESS'),
    CLUSTER_SAVE_FAIL: type('CLUSTER_SAVE_FAIL'),
    CLEAR_CLUSTER_SAVE_STATE: type('CLEAR_CLUSTER_SAVE_STATE'),

    UPDATE_USER: type('UPDATE_USER'),
    UPDATE_USER_SUCCESS: type('UPDATE_USER_SUCCESS'),
    UPDATE_USER_FAIL: type('UPDATE_USER_FAIL'),
    CLEAR_UPDATE_USER_STATE: type('CLEAR_UPDATE_USER_STATE'),

    WIPE_DATA: type('WIPE_DATA'),
    WIPE_DATA_SUCCESS: type('WIPE_DATA_SUCCESS'),
    WIPE_DATA_FAIL: type('WIPE_DATA_FAIL'),
    CLEAR_WIPE_DATA_STATE: type('CLEAR_WIPE_DATA_STATE'),
}

export class CreateUserAction implements Action {
    type = ActionTypes.CREATE_USER;
    constructor(public payload: any) { }
}
export class CreateUserSuccessAction implements Action {
    type = ActionTypes.CREATE_USER_SUCCESS;
    constructor(public payload: any) { }
}
export class CreateUserFailAction implements Action {
    type = ActionTypes.CREATE_USER_FAIL;
    constructor(public payload: any) { }
}
export class ClearCreateUserStateAction implements Action {
    type = ActionTypes.CLEAR_CREATE_USER_STATE;
    constructor(public payload: any) { }
}

export class GetUsersListAction implements Action {
    type = ActionTypes.GET_USERS_LIST;
    constructor(public payload: any) { }
}
export class GetUsersListSuccessAction implements Action {
    type = ActionTypes.GET_USERS_LIST_SUCCESS;
    constructor(public payload: any) { }
}
export class GetUsersListFailAction implements Action {
    type = ActionTypes.GET_USERS_LIST_FAIL;
    constructor(public payload: any) { }
}

export class GetClustersListAction implements Action {
    type = ActionTypes.GET_CLUSTERS_LIST;
    constructor(public payload: any) { }
}
export class GetClustersListSuccessAction implements Action {
    type = ActionTypes.GET_CLUSTERS_LIST_SUCCESS;
    constructor(public payload: any) { }
}
export class GetClustersListFailAction implements Action {
    type = ActionTypes.GET_CLUSTERS_LIST_FAIL;
    constructor(public payload: any) { }
}

export class GetShopClusterAction implements Action {
    type = ActionTypes.GET_SHOP_CLUSTER;
    constructor(public payload: any) { }
}
export class GetShopClusterSuccessAction implements Action {
    type = ActionTypes.GET_SHOP_CLUSTER_SUCCESS;
    constructor(public payload: any) { }
}
export class GetShopClusterFailAction implements Action {
    type = ActionTypes.GET_SHOP_CLUSTER_FAIL;
    constructor(public payload: any) { }
}

export class ClusterSaveAction implements Action {
    type = ActionTypes.CLUSTER_SAVE;
    constructor(public payload: any) { }
}
export class ClusterSaveFailAction implements Action {
    type = ActionTypes.CLUSTER_SAVE_FAIL;
    constructor(public payload: any) { }
}
export class ClusterSaveSuccessAction implements Action {
    type = ActionTypes.CLUSTER_SAVE_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearClusterSaveStateAction implements Action {
    type = ActionTypes.CLEAR_CLUSTER_SAVE_STATE;
    constructor(public payload: any) { }
}

export class UpdateUserAction implements Action {
    type = ActionTypes.UPDATE_USER;
    constructor(public payload: any) { }
}
export class UpdateUserFailAction implements Action {
    type = ActionTypes.UPDATE_USER_FAIL;
    constructor(public payload: any) { }
}
export class UpdateUserSuccessAction implements Action {
    type = ActionTypes.UPDATE_USER_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearUpdateUserStateAction implements Action {
    type = ActionTypes.CLEAR_UPDATE_USER_STATE;
    constructor(public payload: any) { }
}

export class WipeDataAction implements Action {
    type = ActionTypes.WIPE_DATA;
    constructor(public payload: any) { }
}
export class WipeDataFailAction implements Action {
    type = ActionTypes.WIPE_DATA_FAIL;
    constructor(public payload: any) { }
}
export class WipeDataSuccessAction implements Action {
    type = ActionTypes.WIPE_DATA_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearWipeDataStateAction implements Action {
    type = ActionTypes.CLEAR_WIPE_DATA_STATE;
    constructor(public payload: any) { }
}

export type Actions =
    | CreateUserAction
    | CreateUserFailAction
    | CreateUserSuccessAction
    | ClearCreateUserStateAction
    | GetUsersListAction
    | GetUsersListFailAction
    | GetUsersListSuccessAction
    | GetClustersListAction
    | GetClustersListFailAction
    | GetClustersListSuccessAction
    | GetShopClusterAction
    | GetShopClusterFailAction
    | GetShopClusterSuccessAction
    | ClusterSaveAction
    | ClusterSaveFailAction
    | ClusterSaveSuccessAction
    | ClearClusterSaveStateAction
    | UpdateUserAction
    | UpdateUserFailAction
    | UpdateUserSuccessAction
    | ClearUpdateUserStateAction
    | WipeDataAction
    | WipeDataFailAction
    | WipeDataSuccessAction
    | ClearWipeDataStateAction;


