export class ShopSettingsModel {
    public appsettings: {
        androidId: string;
        appleBundleId: string;
        applePrefixId: string;
        currency: string;
        isocurrency: string;
        isolng: string;
        lng: string;
        symbolcurrency: string;
    };

    public associations: {
        img_links: any;
    };

    public dateAdd: string;
    public dateUpd: string;
    public email: string;
    public id: number;
    public idclient: string;
    public idshopname: string;
    public ip: string;
    public linkshop: string;
    public mobile: string;

    public paypaldata: {
        clientId: string;
        secret: string;
        paymentState: number;
    };

    public prestashopconfigurations: any;
    public prestashopsettings: any;

    public stripedata: {
        secretKey: string;
        paymentState: number;
    };

    public token;
    public websevicedata: {
        addressWsKey: string;
        allWsKey: string;
        carrierWsKey: string;
        cartWsKey: string;
        categoryWsKey: string;
        cookieKey: string;
        customerWsKey: string;
        guestWsKey: string;
        imageWsKey: string;
        languageWsKey: string;
        linkapi: string;
        manufacturerWsKey: string;
        onesignalAppId: string;
        orderWsKey: string;
        productWsKey: string;
    };

    constructor(res: any) {
        this.appsettings = res.appsettings || {};
        this.associations = res.associations || {};
        this.dateAdd = res.date_add || '';
        this.dateUpd = res.date_upd || '';
        this.email = res.email;
        this.id = res.id;
        this.idclient = res.idclient;
        this.idshopname = res.idshopname;
        this.ip = res.ip;
        this.linkshop = res.linkshop;
        this.mobile = res.mobile;
        this.paypaldata = res.paypaldata || {};
        this.prestashopconfigurations = res.prestashopconfigurations || {};
        this.prestashopsettings = res.prestashopsettings || {};
        this.stripedata = res.stripedata || {};
        this.token = res.token;
        this.websevicedata = res.websevicedata || {};
    }
}

