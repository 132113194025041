import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import * as actions from './banner.action';
import { BannerService } from './banner.service';

@Injectable()
export class BannerEffect {
    constructor(
        private actions$: Actions,
        private bannerService: BannerService
    ) { }

    addBanner$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.ADD_BANNER),
        map((action: actions.AddBannerAction) => action.payload),
        switchMap(state => {
            return this.bannerService.addBanner(state).pipe(
                map(data => new actions.AddBannerSuccessAction(data)),
                catchError(err => of(new actions.AddBannerFailAction(err)))
            );
        })
    ));

    updateBanner$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.UPDATE_BANNER),
        map((action: actions.UpdateBannerAction) => action.payload),
        switchMap(state => {
            const id = state.id;
            const params = state.params;
            return this.bannerService.updateBanner(id, params).pipe(
                map(data => new actions.UpdateBannerSuccessAction(data)),
                catchError(err => of(new actions.UpdateBannerFailAction(err)))
            );
        })
    ));

    getBannerItem$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_BANNER_ITEM),
        map((action: actions.GetBannerItemAction) => action.payload),
        switchMap(state => {
            return this.bannerService.getBanner(state).pipe(
                map(data => new actions.GetBannerItemSuccessAction(data)),
                catchError(err => of(new actions.GetBannerItemFailAction(err)))
            );
        })
    ));

    deleteBannerItem$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.DELETE_BANNER_ITEM),
        map((action: actions.DeleteBannerItemSuccessAction) => action.payload),
        switchMap(state => {
            return this.bannerService.deleteBanner(state).pipe(
                map(data => new actions.DeleteBannerItemSuccessAction(data)),
                catchError(err => of(new actions.DeleteBannerItemFailAction(err)))
            );
        })
    ));

    getBannerList$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_BANNER_LIST),
        map((action: actions.GetBannerListAction) => action.payload),
        switchMap(state => {
            return this.bannerService.getBannerList(state).pipe(
                map(data => new actions.GetBannerListSuccessAction(data)),
                catchError(err => of(new actions.GetBannerListFailAction(err)))
            );
        })
    ));

    addBannerSlider$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.ADD_BANNER_SLIDER),
        map((action: actions.AddBannerSliderAction) => action.payload),
        switchMap(state => {
            return this.bannerService.addBannerSlider(state).pipe(
                map(data => new actions.AddBannerSliderSuccessAction(data)),
                catchError(err => of(new actions.AddBannerSliderFailAction(err)))
            );
        })
    ));

    deleteBannerSlider$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.DELETE_BANNER_SLIDER),
        map((action: actions.DeleteBannerSliderAction) => action.payload),
        switchMap(state => {
            return this.bannerService.deleteBannerSlider(state).pipe(
                map(data => new actions.DeleteBannerSliderSuccessAction(data)),
                catchError(err => of(new actions.DeleteBannerSliderFailAction(err)))
            );
        })
    ));

    getBannerSliderList$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_BANNER_SLIDER_LIST),
        map((action: actions.GetBannerSliderListAction) => action.payload),
        switchMap(state => {
            return this.bannerService.getBannerSliderList(state).pipe(
                map(data => new actions.GetBannerSliderListSuccessAction(data)),
                catchError(err => of(new actions.GetBannerSliderListFailAction(err)))
            );
        })
    ));

    updateBanneerSlider$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.UPDATE_BANNER_SLIDER_ITEM),
        map((action: actions.UpdateBannerSliderItemAction) => action.payload),
        switchMap(state => {
            const id = state.id;
            const params = state.params;
            return this.bannerService.updateBannerSliderItem(id, params).pipe(
                map(data => new actions.UpdateBannerSliderItemSuccessAction(data)),
                catchError(err => of(new actions.UpdateBannerSliderItemFailAction(err)))
            );
        })
    ));

    getBannerSliderItem$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_BANNER_SLIDER_ITEM),
        map((action: actions.GetBannerSliderItemAction) => action.payload),
        switchMap(state => {
            return this.bannerService.getBannerSliderItem(state).pipe(
                map(data => new actions.GetBannerSliderItemSuccessAction(data)),
                catchError(err => of(new actions.GetBannerSliderItemFailAction(err)))
            );
        })
    ));
}


