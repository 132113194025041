import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api } from '../api';

@Injectable()
export class RootService extends Api {
    private rootUrl: string = this.getRootApi() + this.getApiVersion();
    private apiUrl: string = this.getApiUrl() + this.getApiVersion();

    constructor(private http: HttpClient) {
        super();
    }

    public createUser(params: any): Observable<any> {
        return this.http.post(this.rootUrl + '/user-create', params);
    }

    public getUsersList(params: any): Observable<any> {
        return this.http.post(this.rootUrl + '/users-list', params);
    }

    public getClustersList(params: any): Observable<any> {
        return this.http.get(this.rootUrl + '/clusters-list', params);
    }

    public getShopCluster(params: any): Observable<any> {
        return this.http.post(this.getRootApi() + '/shop-cluster', params);
    }

    public clusterSave(params: any): Observable<any> {
        return this.http.post(this.rootUrl + '/cluster-save', params);
    }

    public updateUser(params: any): Observable<any> {
        return this.http.post(this.rootUrl + '/user-save', params);
    }

    public wipeData(cluster_id: string, params: any): Observable<any> {
        return this.http.post(this.getApiUrl() + cluster_id + '/api/dashboard/' + this.getApiVersion() + '/delete-data', params);
    }
}

