import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccountSandbox } from 'app/core/account/account.sandbox';
import { UserModel } from 'app/core/account/models/registerUser.model';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {
    @Input() showAvatar: boolean = true;
    user: UserModel;

    private subs: Subscription[] = [];

    constructor(
        private _router: Router,
        public accountSandbox: AccountSandbox,
        private cd: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.subs.push(this.accountSandbox.getCurrentUserData$.subscribe((res: UserModel) => {
            if (res) {
                this.user = res;
                this.cd.markForCheck();
            }
        }));
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    signOut(): void {
        this._router.navigate(['/sign-out']);
    }
}
