import { createSelector } from 'reselect';
import { AppState } from '../state.interface';

import * as reducer from './root.reducer';

export const getState = (state: AppState) => state.root;

export const getUsersListLoading = createSelector(getState, reducer.getUsersListLoading);
export const getUsersListLoaded = createSelector(getState, reducer.getUsersListLoaded);
export const getUsersListFail = createSelector(getState, reducer.getUsersListFail);
export const getUsersListData = createSelector(getState, reducer.getUsersListData);

export const createUserLoading = createSelector(getState, reducer.createUserLoading);
export const createUserLoaded = createSelector(getState, reducer.createUserLoaded);
export const createUserFail = createSelector(getState, reducer.createUserFail);
export const createUserData = createSelector(getState, reducer.createUserData);
export const createUserErrMess = createSelector(getState, reducer.createUserErrMess);

export const getClustersListLoading = createSelector(getState, reducer.getClustersListLoading);
export const getClustersListLoaded = createSelector(getState, reducer.getClustersListLoaded);
export const getClustersListFail = createSelector(getState, reducer.getClustersListFail);
export const getClustersListData = createSelector(getState, reducer.getClustersListData);

export const getShopClusterLoading = createSelector(getState, reducer.getShopClusterLoading);
export const getShopClusterLoaded = createSelector(getState, reducer.getShopClusterLoaded);
export const getShopClusterFail = createSelector(getState, reducer.getShopClusterFail);
export const getShopClusterData = createSelector(getState, reducer.getShopClusterData);

export const clusterSaveLoading = createSelector(getState, reducer.clusterSaveLoading);
export const clusterSaveLoaded = createSelector(getState, reducer.clusterSaveLoaded);
export const clusterSaveFail = createSelector(getState, reducer.clusterSaveFail);
export const clusterSaveData = createSelector(getState, reducer.clusterSaveData);
export const clusterSaveMsg = createSelector(getState, reducer.clusterSaveMsg);

export const updateUserLoading = createSelector(getState, reducer.updateUserLoading);
export const updateUserLoaded = createSelector(getState, reducer.updateUserLoaded);
export const updateUserFail = createSelector(getState, reducer.updateUserFail);
export const updateUserData = createSelector(getState, reducer.updateUserData);

export const wipeDataLoading = createSelector(getState, reducer.wipeDataLoading);
export const wipeDataLoaded = createSelector(getState, reducer.wipeDataLoaded);
export const wipeDataFail = createSelector(getState, reducer.wipeDataFail);
export const wipeDataData = createSelector(getState, reducer.wipeDataData);


