<h1 mat-dialog-title>{{'Are you sure?' | translate}}</h1>
<div mat-dialog-content>
    <p>
        {{'Do you want confirm?' | translate}}
    </p>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="confirm()">
        <mat-icon class="icon-size-5 mr-2">done</mat-icon>
        {{'Yes' | translate}}
    </button>
    <button mat-raised-button color="primary" (click)="cancel()">
        <mat-icon class="icon-size-5 mr-2">close</mat-icon>
        {{'No' | translate}}
    </button>
</div>