export class PageInfoModel {
    public id: number;
    public position: number;
    public title: any;

    constructor(res: any) {
        this.id = res.id;
        this.position = res.position;
        this.title = res.title;
    }
}

