import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonSandbox } from 'app/core/common/common.sandbox';
import { Subscription } from 'rxjs';


@Component({
    templateUrl: './upload-image.dialog.html',
    styleUrls: ['./upload-image.dialog.scss']
})
export class UploadImageDialogComponent implements OnInit, OnDestroy {
    private subs: Subscription[] = [];

    height: FormControl = new FormControl(100, Validators.required);
    width: FormControl = new FormControl(100, Validators.required);

    resizeForm: FormGroup = new FormGroup({
        height: this.height,
        width: this.width
    });

    file: File;
    imgURL: string;

    enableResizing = false;

    constructor(
        private commonSandbox: CommonSandbox,
        private dialogRef: MatDialogRef<UploadImageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        // private message: MessageService
    ) { }

    ngOnInit(): void {
        this.commonSandbox.doClearUploadImageFileState();

        this.subs.push(this.commonSandbox.uploadFileData$.subscribe(res => {
            // console.log(res);
            if (res) {
                this.commonSandbox.doClearUploadImageFileState();
                this.dialogRef.close(res);
            }
        }));
        this.subs.push(this.commonSandbox.uploadFileErrMess$.subscribe(res => {
            if (res) {
                // this.message.log(res);
            }
        }));
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    onFileChanged(event: any): void {
        const f: File = event.target.files[0] as File;

        if (f && f.size < 31457280) {
            this.file = f;
            const reader = new FileReader();
            reader.readAsDataURL(this.file);
            reader.onload = (e) => {
                this.imgURL = reader.result.toString();
            };
        } else {
            // this.message.log('File size must be lowerest than 30 mb');
        }
    }

    onUpload(): void {
        const params: any = {};

        if (this.enableResizing) {
            if (this.resizeForm.invalid) {
                return;
            } else {
                params.resizeHeightImage = this.height.value;
                params.resizeWidthImage = this.width.value;
            }
        }

        this.commonSandbox.doUploadImageFile(this.file, params);
    }
}

