<h1 mat-dialog-title>{{'Create user' | translate}}</h1>
<div mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field class="example-full-width">
            <mat-label>{{'Id' | translate}}</mat-label>
            <input matInput placeholder="{{'Id' | translate}}" type="text" required readonly [formControl]="clusterId">
            <mat-error *ngIf="clusterId.hasError('required')">
                {{'Required' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>{{'Name' | translate}}</mat-label>
            <input matInput placeholder="{{'Name' | translate}}" type="text" required [formControl]="name">
            <mat-error *ngIf="name.hasError('required')">
                {{'Required' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{'Active' | translate}}</mat-label>
            <mat-select [formControl]="active">
                <mat-option [value]="true">
                    {{'Active' | translate}}
                </mat-option>
                <mat-option [value]="false">
                    {{'Inactive' | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>

    </form>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="confirm()" [disabled]="form.invalid">
        <ng-container *ngIf="!data">
            <mat-icon class="icon-size-5 mr-2">add</mat-icon>
            {{'Create' | translate}}
        </ng-container>
        <ng-container *ngIf="data">
            <mat-icon class="icon-size-5 mr-2">sync</mat-icon>
            {{'Edit' | translate}}
        </ng-container>
    </button>
    <button mat-raised-button color="primary" (click)="cancel()">
        <mat-icon class="icon-size-5 mr-2">close</mat-icon>
        {{'Cancel' | translate}}
    </button>
</div>