import { CommonRecord, CommonState } from './common.state';
import * as actions from './common.action';
import { CategoryModel } from './models/category.model';
import { ImageFileModel } from './models/image-file.model';
import { PageInfoModel } from './models/page-info.model';
import { ProductModel } from './models/product.model';
import { CustomerModel } from './models/customer.model';
import { OrderStatusSettingsModel } from './models/order-status-settings.model';

export const initialState: CommonState = (new CommonRecord() as unknown) as CommonState;

export function reducer(state = initialState, { type, payload }: any): CommonState {
    if (!type) {
        return state;
    }

    switch (type) {
        case actions.ActionTypes.GET_PAYMENT_LIST: {
            return Object.assign({}, state, {
                getPaymentListLoading: true,
                getPaymentListLoaded: false,
                getPaymentListFail: false,
                getPaymentListData: {},
            });
        }
        case actions.ActionTypes.GET_PAYMENT_LIST_SUCCESS: {
            return Object.assign({}, state, {
                getPaymentListLoading: false,
                getPaymentListLoaded: true,
                getPaymentListFail: false,
                getPaymentListData: payload,
            });
        }
        case actions.ActionTypes.GET_PAYMENT_LIST_FAIL: {
            return Object.assign({}, state, {
                getPaymentListLoading: false,
                getPaymentListLoaded: false,
                getPaymentListFail: true,
                getPaymentListData: {},
            });
        }

        case actions.ActionTypes.GET_CATEGORY_LIST: {
            return Object.assign({}, state, {
                getCategoryListLoading: true,
                getCategoryListLoaded: false,
                getCategoryListFail: false,
                getCategoryListData: null
            });
        }
        case actions.ActionTypes.GET_CATEGORY_LIST_SUCCESS: {
            let catData = [];
            if (payload && payload.data && !!payload.data.length) {
                catData = payload.data.map((el: any) => new CategoryModel(el));
            }
            return Object.assign({}, state, {
                getCategoryListLoading: false,
                getCategoryListLoaded: true,
                getCategoryListFail: false,
                getCategoryListData: catData
            });
        }
        case actions.ActionTypes.GET_CATEGORY_LIST_FAIL: {
            return Object.assign({}, state, {
                getCategoryListLoading: false,
                getCategoryListLoaded: false,
                getCategoryListFail: true,
                getCategoryListData: null
            });
        }

        case actions.ActionTypes.GET_CATEGORY_ITEM: {
            return Object.assign({}, state, {
                getCategoryItemLoading: true,
                getCategoryItemLoaded: false,
                getCategoryItemFail: false,
                getCategoryItemData: null,
            });
        }
        case actions.ActionTypes.GET_CATEGORY_ITEM_SUCCESS: {
            let cat;
            if (payload && payload.data && payload.data[0]) {
                cat = new CategoryModel(payload.data[0]);
            }
            return Object.assign({}, state, {
                getCategoryItemLoading: false,
                getCategoryItemLoaded: true,
                getCategoryItemFail: false,
                getCategoryItemData: cat,
            });
        }
        case actions.ActionTypes.GET_CATEGORY_ITEM_FAIL: {
            return Object.assign({}, state, {
                getCategoryItemLoading: false,
                getCategoryItemLoaded: false,
                getCategoryItemFail: true,
                getCategoryItemData: null,
            });
        }
        case actions.ActionTypes.CLEAR_GET_CATEGORY_ITEM_STATE: {
            return Object.assign({}, state, {
                getCategoryItemLoading: false,
                getCategoryItemLoaded: false,
                getCategoryItemFail: false,
                getCategoryItemData: null,
            });
        }

        case actions.ActionTypes.UPLOAD_FILE: {
            return Object.assign({}, state, {
                uploadFileLoading: false,
                uploadFileLoaded: false,
                uploadFileFail: false,
                uploadFileData: null
            });
        }
        case actions.ActionTypes.UPLOAD_FILE_SUCCESS: {
            // console.log(payload);
            return Object.assign({}, state, {
                uploadFileLoading: false,
                uploadFileLoaded: true,
                uploadFileFail: false,
                uploadFileData: payload && payload.data ? new ImageFileModel(payload.data) : null,
                uploadFileErrMess: payload.message && payload.isError ? payload.message : null
            });
        }
        case actions.ActionTypes.UPLOAD_FILE_FAIL: {
            return Object.assign({}, state, {
                uploadFileLoading: false,
                uploadFileLoaded: false,
                uploadFileFail: true,
                uploadFileData: null,
                uploadFileErrMess: payload.message ? payload.message : null
            });
        }
        case actions.ActionTypes.CLEAR_UPLOAD_FILE_STATE: {
            return Object.assign({}, state, {
                uploadFileLoading: false,
                uploadFileLoaded: false,
                uploadFileFail: false,
                uploadFileData: null
            });
        }

        case actions.ActionTypes.UPDATE_POSITION: {
            return Object.assign({}, state, {
                updatePositionLoading: true,
                updatePositionLoaded: false,
                updatePositionFail: false,
            });
        }
        case actions.ActionTypes.UPDATE_POSITION_SUCCESS: {
            return Object.assign({}, state, {
                updatePositionLoading: false,
                updatePositionLoaded: true,
                updatePositionFail: false,
            });
        }
        case actions.ActionTypes.UPDATE_POSITION_FAIL: {
            return Object.assign({}, state, {
                updatePositionLoading: false,
                updatePositionLoaded: false,
                updatePositionFail: true,
            });
        }
        case actions.ActionTypes.CLEAR_UPDATE_POSITION_STATE: {
            return Object.assign({}, state, {
                updatePositionLoading: false,
                updatePositionLoaded: false,
                updatePositionFail: false,
            });
        }

        case actions.ActionTypes.GET_SHOP_PAGES: {
            return Object.assign({}, state, {
                shopPagesLoading: true,
                shopPagesLoaded: false,
                shopPagesFail: false,
                shopPagesData: null
            });
        }
        case actions.ActionTypes.GET_SHOP_PAGES_SUCCESS: {
            let pageslist;
            if (payload && payload.data && !!payload.data.length) {
                pageslist = payload.data.map((el: any) => new PageInfoModel(el));
            }
            return Object.assign({}, state, {
                shopPagesLoading: false,
                shopPagesLoaded: true,
                shopPagesFail: false,
                shopPagesData: pageslist
            });
        }
        case actions.ActionTypes.GET_SHOP_PAGES_FAIL: {
            return Object.assign({}, state, {
                shopPagesLoading: false,
                shopPagesLoaded: false,
                shopPagesFail: true,
                shopPagesData: null
            });
        }

        case actions.ActionTypes.GET_PRODUCTS_LIST: {
            return Object.assign({}, state, {
                getProductsListLoading: true,
                getProductsListLoaded: false,
                getProductsListFail: false,
                getProductsListData: null
            });
        }
        case actions.ActionTypes.GET_PRODUCTS_LIST_SUCCESS: {
            // console.log(payload);
            let products = [];
            if (payload.data && !!payload.data.length) {
                products = payload.data.map((el: any) => new ProductModel(el));
            }
            return Object.assign({}, state, {
                getProductsListLoading: false,
                getProductsListLoaded: true,
                getProductsListFail: false,
                getProductsListData: products
            });
        }
        case actions.ActionTypes.GET_PRODUCTS_LIST_FAIL: {
            return Object.assign({}, state, {
                getProductsListLoading: false,
                getProductsListLoaded: false,
                getProductsListFail: true,
                getProductsListData: null
            });
        }

        case actions.ActionTypes.GET_PRODUCT_ITEM: {
            return Object.assign({}, state, {
                getProductItemLoading: true,
                getProductItemLoaded: false,
                getProductItemFail: false,
                getProductItemData: null
            });
        }
        case actions.ActionTypes.GET_PRODUCT_ITEM_SUCCESS: {
            // console.log(payload);
            let product;
            if (payload.data && payload.data[0]) {
                product = new ProductModel(payload.data[0]);
            }
            return Object.assign({}, state, {
                getProductItemLoading: false,
                getProductItemLoaded: true,
                getProductItemFail: false,
                getProductItemData: product
            });
        }
        case actions.ActionTypes.GET_PRODUCT_ITEM_FAIL: {
            return Object.assign({}, state, {
                getProductItemLoading: false,
                getProductItemLoaded: false,
                getProductItemFail: true,
                getProductItemData: null
            });
        }
        case actions.ActionTypes.CLEAR_GET_PRODUCT_ITEM_STATE: {
            return Object.assign({}, state, {
                getProductItemLoading: false,
                getProductItemLoaded: false,
                getProductItemFail: false,
                getProductItemData: null
            });
        }

        case actions.ActionTypes.ADD_NOTIFICATION: {
            return Object.assign({}, state, {
                addNotificationLoading: true,
                addNotificationLoaded: false,
                addNotificationFail: false,
                addNotificationData: null
            });
        }
        case actions.ActionTypes.ADD_NOTIFICATION_SUCCESS: {
            return Object.assign({}, state, {
                addNotificationLoading: false,
                addNotificationLoaded: true,
                addNotificationFail: false,
                addNotificationData: payload
            });
        }
        case actions.ActionTypes.ADD_NOTIFICATION_FAIL: {
            return Object.assign({}, state, {
                addNotificationLoading: false,
                addNotificationLoaded: false,
                addNotificationFail: true,
                addNotificationData: null
            });
        }
        case actions.ActionTypes.CLEAR_ADD_NOTIFICATION_STATE: {
            return Object.assign({}, state, {
                addNotificationLoading: false,
                addNotificationLoaded: false,
                addNotificationFail: false,
                addNotificationData: null
            });
        }

        case actions.ActionTypes.GET_CUSTOMERS_LIST: {
            return Object.assign({}, state, {
                getCustomersListLoading: true,
                getCustomersListLoaded: false,
                getCustomersListFail: false,
                getCustomersListData: null
            });
        }
        case actions.ActionTypes.GET_CUSTOMERS_LIST_SUCCESS: {
            let customers: CustomerModel[] = [];
            if (payload && payload.customers && !!payload.customers.length) {
                customers = payload.customers.map((el: any) => new CustomerModel(el));
            }

            return Object.assign({}, state, {
                getCustomersListLoading: false,
                getCustomersListLoaded: true,
                getCustomersListFail: false,
                getCustomersListData: customers
            });
        }
        case actions.ActionTypes.GET_CUSTOMERS_LIST_FAIL: {
            return Object.assign({}, state, {
                getCustomersListLoading: false,
                getCustomersListLoaded: false,
                getCustomersListFail: true,
                getCustomersListData: null
            });
        }
        case actions.ActionTypes.CLEAR_GET_CUSTOMERS_LIST_STATE: {
            return Object.assign({}, state, {
                getCustomersListLoading: false,
                getCustomersListLoaded: false,
                getCustomersListFail: false,
                getCustomersListData: null
            });
        }

        case actions.ActionTypes.GET_ORDER_STATUS_SETTINGS: {
            return Object.assign({}, state, {
                getOrderStatusSettingsLoading: true,
                getOrderStatusSettingsLoaded: false,
                getOrderStatusSettingsFail: false,
                getOrderStatusSettingsData: null,
            });
        }
        case actions.ActionTypes.GET_ORDER_STATUS_SETTINGS_SUCCESS: {
            return Object.assign({}, state, {
                getOrderStatusSettingsLoading: false,
                getOrderStatusSettingsLoaded: true,
                getOrderStatusSettingsFail: false,
                getOrderStatusSettingsData: payload && payload.data && payload.success ? new OrderStatusSettingsModel(payload.data) : null,
            });
        }
        case actions.ActionTypes.GET_ORDER_STATUS_SETTINGS_FAIL: {
            return Object.assign({}, state, {
                getOrderStatusSettingsLoading: false,
                getOrderStatusSettingsLoaded: false,
                getOrderStatusSettingsFail: true,
                getOrderStatusSettingsData: null,
            });
        }
        case actions.ActionTypes.CLEAR_GET_ORDER_STATUS_SETTINGS_STATE: {
            return Object.assign({}, state, {
                getOrderStatusSettingsLoading: false,
                getOrderStatusSettingsLoaded: false,
                getOrderStatusSettingsFail: false,
                getOrderStatusSettingsData: null,
            });
        }

        case actions.ActionTypes.SET_ORDER_STATUS_SETTINGS: {
            return Object.assign({}, state, {
                setOrderStatusSettingsLoading: true,
                setOrderStatusSettingsLoaded: false,
                setOrderStatusSettingsFail: false,
                setOrderStatusSettingsData: null
            });
        }
        case actions.ActionTypes.SET_ORDER_STATUS_SETTINGS_SUCCESS: {
            return Object.assign({}, state, {
                setOrderStatusSettingsLoading: false,
                setOrderStatusSettingsLoaded: true,
                setOrderStatusSettingsFail: false,
                setOrderStatusSettingsData: payload
            });
        }
        case actions.ActionTypes.SET_ORDER_STATUS_SETTINGS_FAIL: {
            return Object.assign({}, state, {
                setOrderStatusSettingsLoading: false,
                setOrderStatusSettingsLoaded: false,
                setOrderStatusSettingsFail: true,
                setOrderStatusSettingsData: null
            });
        }
        case actions.ActionTypes.CLEAR_SET_ORDER_STATUS_SETTINGS_STATE: {
            return Object.assign({}, state, {
                setOrderStatusSettingsLoading: false,
                setOrderStatusSettingsLoaded: false,
                setOrderStatusSettingsFail: false,
                setOrderStatusSettingsData: null
            });
        }
        // 
        case actions.ActionTypes.GET_PRESTASHOP_PRODUCTS_LIST: {
            return Object.assign({}, state, {
                getPrestashopProductsListData: null,
                getPrestashopProductsListFailData: null,
                getPrestashopProductsListLoading: true,
            })
        }
        case actions.ActionTypes.GET_PRESTASHOP_PRODUCTS_LIST_FAIL: {
            return Object.assign({}, state, {
                getPrestashopProductsListData: null,
                getPrestashopProductsListFailData: payload,
                getPrestashopProductsListLoading: false,
            })
        }
        case actions.ActionTypes.GET_PRESTASHOP_PRODUCTS_LIST_SUCCESS: {
            return Object.assign({}, state, {
                getPrestashopProductsListData: payload,
                getPrestashopProductsListFailData: null,
                getPrestashopProductsListLoading: false,
            })
        }
        // 
        case actions.ActionTypes.VIDEO_UPLOAD: {
            return Object.assign({}, state, {
                videoUploadData: null,
                videoUploadFailData: null,
                videoUploadLoading: true,
            })
        }
        case actions.ActionTypes.VIDEO_UPLOAD_SUCCESS: {
            return Object.assign({}, state, {
                videoUploadData: payload,
                videoUploadFailData: null,
                videoUploadLoading: false,
            })
        }
        case actions.ActionTypes.VIDEO_UPLOAD_FAIL: {
            return Object.assign({}, state, {
                videoUploadData: null,
                videoUploadFailData: payload,
                videoUploadLoading: false,
            })
        }
        case actions.ActionTypes.CLEAR_VIDEO_UPLOAD_STATE: {
            return Object.assign({}, state, {
                videoUploadData: null,
                videoUploadFailData: null,
                videoUploadLoading: false,
            })
        }

        default: {
            return state;
        }
    }
}

export const getPaymentListLoading = (state: CommonState) => state.getPaymentListLoading;
export const getPaymentListLoaded = (state: CommonState) => state.getPaymentListLoaded;
export const getPaymentListFail = (state: CommonState) => state.getPaymentListFail;
export const getPaymentListData = (state: CommonState) => state.getPaymentListData;

export const getCategoryListLoading = (state: CommonState) => state.getCategoryListLoading;
export const getCategoryListLoaded = (state: CommonState) => state.getCategoryListLoaded;
export const getCategoryListFail = (state: CommonState) => state.getCategoryListFail;
export const getCategoryListData = (state: CommonState) => state.getCategoryListData;

export const getCategoryItemLoading = (state: CommonState) => state.getCategoryItemLoading;
export const getCategoryItemLoaded = (state: CommonState) => state.getCategoryItemLoaded;
export const getCategoryItemFail = (state: CommonState) => state.getCategoryItemFail;
export const getCategoryItemData = (state: CommonState) => state.getCategoryItemData;

export const uploadFileLoading = (state: CommonState) => state.uploadFileLoading;
export const uploadFileLoaded = (state: CommonState) => state.uploadFileLoaded;
export const uploadFileFail = (state: CommonState) => state.uploadFileFail;
export const uploadFileData = (state: CommonState) => state.uploadFileData;
export const uploadFileErrMess = (state: CommonState) => state.uploadFileErrMess;

export const updatePositionLoading = (state: CommonState) => state.updatePositionLoading;
export const updatePositionLoaded = (state: CommonState) => state.updatePositionLoaded;
export const updatePositionFail = (state: CommonState) => state.updatePositionFail;

export const shopPagesLoading = (state: CommonState) => state.shopPagesLoading;
export const shopPagesLoaded = (state: CommonState) => state.shopPagesLoaded;
export const shopPagesFail = (state: CommonState) => state.shopPagesFail;
export const shopPagesData = (state: CommonState) => state.shopPagesData;

export const getProductsListLoading = (state: CommonState) => state.getProductsListLoading;
export const getProductsListLoaded = (state: CommonState) => state.getProductsListLoaded;
export const getProductsListFail = (state: CommonState) => state.getProductsListFail;
export const getProductsListData = (state: CommonState) => state.getProductsListData;

export const getProductItemLoading = (state: CommonState) => state.getProductItemLoading;
export const getProductItemLoaded = (state: CommonState) => state.getProductItemLoaded;
export const getProductItemFail = (state: CommonState) => state.getProductItemFail;
export const getProductItemData = (state: CommonState) => state.getProductItemData;

export const addNotificationLoading = (state: CommonState) => state.addNotificationLoading;
export const addNotificationLoaded = (state: CommonState) => state.addNotificationLoaded;
export const addNotificationFail = (state: CommonState) => state.addNotificationFail;
export const addNotificationData = (state: CommonState) => state.addNotificationData;

export const getCustomersListLoading = (state: CommonState) => state.getCustomersListLoading;
export const getCustomersListLoaded = (state: CommonState) => state.getCustomersListLoaded;
export const getCustomersListFail = (state: CommonState) => state.getCustomersListFail;
export const getCustomersListData = (state: CommonState) => state.getCustomersListData;

export const getOrderStatusSettingsLoading = (state: CommonState) => state.getOrderStatusSettingsLoading;
export const getOrderStatusSettingsLoaded = (state: CommonState) => state.getOrderStatusSettingsLoaded;
export const getOrderStatusSettingsFail = (state: CommonState) => state.getOrderStatusSettingsFail;
export const getOrderStatusSettingsData = (state: CommonState) => state.getOrderStatusSettingsData;

export const setOrderStatusSettingsLoading = (state: CommonState) => state.setOrderStatusSettingsLoading;
export const setOrderStatusSettingsLoaded = (state: CommonState) => state.setOrderStatusSettingsLoaded;
export const setOrderStatusSettingsFail = (state: CommonState) => state.setOrderStatusSettingsFail;
export const setOrderStatusSettingsData = (state: CommonState) => state.setOrderStatusSettingsData;
// 
export const getPrestashopProductsListData = (state: CommonState) => state.getPrestashopProductsListData;
export const getPrestashopProductsListFailData = (state: CommonState) => state.getPrestashopProductsListFailData;
export const getPrestashopProductsListLoading = (state: CommonState) => state.getPrestashopProductsListLoading;
// 
export const videoUploadData = (state: CommonState) => state.videoUploadData;
export const videoUploadFailData = (state: CommonState) => state.videoUploadFailData;
export const videoUploadLoading = (state: CommonState) => state.videoUploadLoading;

