import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, Subscription } from 'rxjs';
import { AccountSandbox } from './core/account/account.sandbox';
import { UserModel } from './core/account/models/registerUser.model';
import { NavigationService } from './core/navigation/navigation.service';
import { AppSettingService } from './core/shared/app.settings';
import { USER_ROLES } from './core/shared/helpers/constants.enum';
import { ShopSandbox } from './core/shop/shop.sandbox';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    private subs: Subscription[] = [];

    constructor(
        private shopSandbox: ShopSandbox,
        private accountSandbox: AccountSandbox,
        private router: Router,
        private appSettings: AppSettingService,
        @Inject(DOCUMENT) private document: Document,
        private titleService: Title,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,

        private navService: NavigationService
    ) { }

    ngOnInit(): void {
        this.translate.setDefaultLang('es');
        this.translate.use('es');
        this.document.documentElement.lang = this.translate.currentLang;

        const appTitle = this.titleService.getTitle();

        this.checkCurrentUser();

        this.subs.push(this.accountSandbox.getCurrentUserData$.subscribe((res: UserModel) => {
            if (res) {
                this.shopSandbox.doClearShopSettingsState();
                this.appSettings.setCurrentUser(res);
                if (res.role) {
                    this.navService.changeUserRole(res.role as USER_ROLES);
                }

                if (res.email && res.role !== USER_ROLES.admin && res.role !== USER_ROLES.root) {
                    this.shopSandbox.doGetShopSettings({
                        email: res.email
                    });
                }
            }
        }));

        this.subs.push(this.shopSandbox.currentShopName$.subscribe(shopName => {
            this.navService.changeShopname(shopName);
        }));

        this.subs.push(this.accountSandbox.doLogOutEmitted$.subscribe(res => {
            if (res) {
                localStorage.clear();
                sessionStorage.clear();
                this.accountSandbox.doClearLoginState();
                this.accountSandbox.doLogoutSuccess();
                this.appSettings.clerCurrentUser();
                this.shopSandbox.doClearShopSettingsState();
                // this.message.log('User logouted!');
            }
        }));
        this.subs.push(this.accountSandbox.loginUserData$.subscribe(user => {
            if (user) {
                this.appSettings.setCurrentUser(user);
                // this.checkCurrentUser();

                this.accountSandbox.doGetCurrentUser();
            }
        }));
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => {
                let child = this.activatedRoute.firstChild;
                while (child.firstChild) {
                    child = child.firstChild;
                }
                if (child.snapshot.data['title']) {
                    return child.snapshot.data['title'];
                }
                return appTitle;
            })
        ).subscribe((ttl: string) => {
            this.titleService.setTitle(ttl);
        })
    }

    private checkCurrentUser(): void {
        if (!this.appSettings.cureentUser && this.appSettings.getUserToken()) {
            // console.log('[Core] - do get current user');

            this.accountSandbox.doGetCurrentUser();
        }
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}
