export const ONESIGNAL_LAN: { [key: string]: string } = {
    en: "English",
    ar: "Arabic",
    az: "Azerbaijani",
    bs: "Bosnian",
    ca: "Catalan",
    "zh-Hans": "Chinese (Simplified)",
    "zh-Hant": "Chinese (Traditional)",
    hr: "Croatian",
    cs: "Czech",
    da: "Danish",
    nl: "Dutch",
    et: "Estonian",
    fi: "Finnish",
    fr: "French",
    ka: "Georgian",
    bg: "Bulgarian",
    de: "German",
    el: "Greek",
    hi: "Hindi",
    he: "Hebrew",
    hu: "Hungarian",
    id: "Indonesian",
    it: "Italian",
    ja: "Japanese",
    ko: "Korean",
    lv: "Latvian",
    lt: "Lithuanian",
    ms: "Malay",
    nb: "Norwegian",
    fa: "Persian",
    pl: "Polish",
    pt: "Portuguese",
    pa: "Punjabi",
    ro: "Romanian",
    ru: "Russian",
    sr: "Serbian",
    sk: "Slovak",
    es: "Spanish",
    sv: "Swedish",
    th: "Thai",
    tr: "Turkish",
    uk: "Ukrainian",
    vi: "Vietnamese"
};

