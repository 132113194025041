<h1 mat-dialog-title>{{'Select customer' | translate}}</h1>
<div mat-dialog-content>
    <div class="con">
        <p>
            ({{idShopname}})
        </p>
        <mat-form-field style="width: 100%;">
            <input type="text" matInput [formControl]="keyword" placeholder="Ex. pat@example.com">
        </mat-form-field>
        <mat-list style="width: 100%; min-height: 100px;">
            <mat-list-item *ngFor="let customer of customers" style="cursor: pointer;" (click)="select(customer)">
                #{{customer?.id}}: {{customer?.email}} - {{customer?.firstname}} {{customer?.lastname}}
            </mat-list-item>
        </mat-list>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="close()">
        <mat-icon class="icon-size-5 mr-2">close</mat-icon>
        {{'Close' | translate}}
    </button>
</div>