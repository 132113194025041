import { Map, Record } from 'immutable';
import { LanguageModel } from './models/language.model';

export interface ShopState extends Map<string, any> {
    addShopLoading: boolean;
    addShopLoaded: boolean;
    addShopFail: boolean;
    addShopData: any;

    getShopSettingsLoading: boolean;
    getShopSettingsLoaded: boolean;
    getShopSettingsFail: boolean;
    getShopSettingsData: any;

    currentShopName: string;

    getMenuItemLoading: boolean;
    getMenuItemLoaded: boolean;
    getMenuItemFail: boolean;
    getMenuItemData: any;

    updateMenuItemLoading: boolean;
    updateMenuItemLoaded: boolean;
    updateMenuItemFail: boolean;
    updateMenuItemData: any;

    getOrderStatusListLoading: boolean;
    getOrderStatusListLoaded: boolean;
    getOrderStatusListFail: boolean;
    getOrderStatusListData: any;

    getShopLangListLoading: boolean;
    getShopLangListLoaded: boolean;
    getShopLangListFail: boolean;
    getShopLangListData: LanguageModel[];

    getBrandsListLoading: boolean;
    getBrandsListLoaded: boolean;
    getBrandsListFail: boolean;
    getBrandsListData: any;

    createPaypalHookLoading: boolean;
    createPaypalHookLoaded: boolean;
    createPaypalHookFail: boolean;
    createPaypalHookData: any;

    createStripeHookLoading: boolean;
    createStripeHookLoaded: boolean;
    createStripeHookFail: boolean;
    createStripeHookData: any;

    getShopErrorListLoading: boolean;
    getShopErrorListLoaded: boolean;
    getShopErrorListFail: boolean;
    getShopErrorListData: any;

    updateErrorStatusLoading: boolean;
    updateErrorStatusLoaded: boolean;
    updateErrorStatusFail: boolean;
    updateErrorStatusData: any;
}

export const ShopRecord = Record({
    addShopLoading: false,
    addShopLoaded: false,
    addShopFail: false,
    addShopData: null,

    getShopSettingsLoading: false,
    getShopSettingsLoaded: false,
    getShopSettingsFail: false,
    getShopSettingsData: null,

    currentShopName: null,

    getMenuItemLoading: false,
    getMenuItemLoaded: false,
    getMenuItemFail: false,
    getMenuItemData: null,

    updateMenuItemLoading: false,
    updateMenuItemLoaded: false,
    updateMenuItemFail: false,
    updateMenuItemData: null,

    getOrderStatusListLoading: false,
    getOrderStatusListLoaded: false,
    getOrderStatusListFail: false,
    getOrderStatusListData: null,

    getShopLangListLoading: false,
    getShopLangListLoaded: false,
    getShopLangListFail: false,
    getShopLangListData: null,

    getBrandsListLoading: false,
    getBrandsListLoaded: false,
    getBrandsListFail: false,
    getBrandsListData: null,

    createPaypalHookLoading: false,
    createPaypalHookLoaded: false,
    createPaypalHookFail: false,
    createPaypalHookData: null,

    createStripeHookLoading: false,
    createStripeHookLoaded: false,
    createStripeHookFail: false,
    createStripeHookData: null,

    getShopErrorListLoading: false,
    getShopErrorListLoaded: false,
    getShopErrorListFail: false,
    getShopErrorListData: null,

    updateErrorStatusLoading: false,
    updateErrorStatusLoaded: false,
    updateErrorStatusFail: false,
    updateErrorStatusData: null
});

