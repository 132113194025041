export class OrderStatusModel {
    public id: string;
    public color: string;
    public module_name: string;
    public name: string;
    public paid: number;
    public send_email: number;
    public shipped: number;

    constructor(res: any) {
        this.id = res.id || null;
        this.color = res.color || null;
        this.module_name = res.module_name ? res.module_name : '';
        this.name = res.name ? res.name : {};
        this.paid = res.paid ? res.paid : 0;
        this.send_email = res.send_email ? res.send_email : 0;
        this.shipped = res.shipped ? res.shipped : 0;
    }
}

