import { createSelector } from 'reselect';
import { AppState } from '../state.interface';

import * as reducer from './account.reducer';

export const getState = (state: AppState) => state.account;

export const registerUserLoading = createSelector(getState, reducer.registerUserLoading);
export const registerUserLoaded = createSelector(getState, reducer.registerUserLoaded);
export const registerUserFail = createSelector(getState, reducer.registerUserFail);
export const registerUserData = createSelector(getState, reducer.registerUserData);
export const registerUserErrors = createSelector(getState, reducer.registerUserErrors);

export const loginUserLoading = createSelector(getState, reducer.loginUserLoading);
export const loginUserLoaded = createSelector(getState, reducer.loginUserLoaded);
export const loginUserFail = createSelector(getState, reducer.loginUserFail);
export const loginUserData = createSelector(getState, reducer.loginUserData);
export const loginUserErrors = createSelector(getState, reducer.loginUserErrors);

export const sendPasswordRecoveryKeyLoading = createSelector(getState, reducer.sendPasswordRecoveryKeyLoading);
export const sendPasswordRecoveryKeyLoaded = createSelector(getState, reducer.sendPasswordRecoveryKeyLoaded);
export const sendPasswordRecoveryKeyFail = createSelector(getState, reducer.sendPasswordRecoveryKeyFail);
export const sendPasswordRecoveryKeyData = createSelector(getState, reducer.sendPasswordRecoveryKeyData);
export const sendPasswordRecoveryKeyErrMessage = createSelector(getState, reducer.sendPasswordRecoveryKeyErrMessage);

export const changePasswordLoading = createSelector(getState, reducer.changePasswordLoading);
export const changePasswordLoaded = createSelector(getState, reducer.changePasswordLoaded);
export const changePasswordFail = createSelector(getState, reducer.changePasswordFail);
export const changePasswordData = createSelector(getState, reducer.changePasswordData);
export const changePasswordErrMessage = createSelector(getState, reducer.changePasswordErrMessage);

export const lastLoginedUserInfo = createSelector(getState, reducer.lastLoginedUserInfo);
export const currentUserToken = createSelector(getState, reducer.currentUserToken);
export const currentUserRole = createSelector(getState, reducer.currentUserRole);

export const doLogOutEmitted = createSelector(getState, reducer.doLogOutEmitted);

export const getCurrentUserLoading = createSelector(getState, reducer.getCurrentUserLoading);
export const getCurrentUserLoaded = createSelector(getState, reducer.getCurrentUserLoaded);
export const getCurrentUserFail = createSelector(getState, reducer.getCurrentUserFail);
export const getCurrentUserData = createSelector(getState, reducer.getCurrentUserData);

// export const currentShopName = createSelector(getState, reducer.currentShopName);


