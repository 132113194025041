// https://e-commerceapp.es/api/prestashop/v1/payment-list?idShopname=prestakit&currency_iso_code=EUR&language_iso_code=en

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Api } from '../api';
import { AppSettingService } from '../shared/app.settings';
// import { AppSettingService } from '../services/app.settings';

@Injectable()
export class CommonService extends Api {
    // private apiUrl: string = this.getApiUrl() + this.getApiVersion();
    // private apiApp: string = this.getApiApp() + this.getApiVersion();

    private url = '/api/dashboard/' + this.getApiVersion();
    private prestaUrl = '/api/prestashop/' + this.getApiVersion();

    constructor(
        private http: HttpClient,
        protected appSettings: AppSettingService
    ) {
        super();
    }

    // public getPaymnetList(): Observable<any> {
    //     return this.http.get('https://e-commerceapp.es/api/prestashop/v1/payme
    // nt-list?idShopname=prestakit&currency_iso_code=EUR&language_iso_code=en');
    // }

    public getCategoryList(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }

        return this.http.get(this.getApiUrl() + this.appSettings.clusterId + this.url + '/categories', { params });
    }

    public uploadFile(file: File, params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }

        const uploadData = new FormData();
        uploadData.append('file', file, file.name);
        uploadData.append('resizeHeightImage', params.resizeHeightImage || 0);
        uploadData.append('resizeWidthImage', params.resizeWidthImage || 0);

        return this.http.post(this.getApiUrl() + this.appSettings.clusterId + this.url + '/image-upload', uploadData);
    }

    public updatePosition(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }

        return this.http.post(this.getApiUrl() + this.appSettings.clusterId + this.url + '/positions-update', params);
    }

    public getShopPages(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }

        return this.http.get(this.getApiUrl() + this.appSettings.clusterId + this.url + '/pages-list', { params });
    }

    public getProductsList(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }

        return this.http.get(this.getApiUrl() + this.appSettings.clusterId + this.url + '/products', { params });
    }

    public addNotification(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }
        return this.http.post(this.getApiUrl() + this.appSettings.clusterId + this.prestaUrl + '/notification-send', params);
    }

    public getCustomerList(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }
        return this.http.get(this.getApiUrl() + this.appSettings.clusterId + this.prestaUrl + '/customer-find', { params });
    }

    public getOrderStatusSettings(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }
        return this.http.get(this.getApiUrl() + this.appSettings.clusterId + this.url + '/get-settings-statusorder', { params });
    }

    public setOrderStatusSettings(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }
        return this.http.post(this.getApiUrl() + this.appSettings.clusterId + this.url + '/settings-statusorder', params);
    }

    public getPrestashopProductsList(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }
        return this.http.get(this.getApiUrl() + this.appSettings.clusterId + this.prestaUrl + '/products', { params });
    }

    public videoUpload(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }
        return this.http.post(this.getApiUrl() + this.appSettings.clusterId + this.url + '/video-upload?' + new URLSearchParams(params), {});
    }
}

