import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ClusterModel } from "app/core/root/models/cluster.model";
import { RootSandbox } from "app/core/root/root.sandbox";
import { Subscription } from "rxjs";

@Component({
    templateUrl: './cluster-create.dialog.html',
    styleUrls: ['./cluster-create.dialog.scss']
})
export class ClusterCreateDialogComponent implements OnInit, OnDestroy {
    private subs: Subscription[] = [];

    form: FormGroup;
    name: FormControl = new FormControl('', [
        Validators.required,
        Validators.pattern('^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$')
    ]);
    clusterId: FormControl = new FormControl({ value: '', disabled: true });
    active: FormControl = new FormControl(true);

    private editedValue: ClusterModel = null;

    constructor(
        private rootSandbox: RootSandbox,
        private dialogRef: MatDialogRef<ClusterCreateDialogComponent>,
        private fb: FormBuilder,
        // private message: MessageService,
        @Inject(MAT_DIALOG_DATA) public data: ClusterModel,
    ) {
        this.editedValue = Object.assign({}, this.data) as ClusterModel;
    }

    ngOnInit(): void {
        this.rootSandbox.doClearClusterSaveState();

        this.form = this.fb.group({
            name: this.name,
            clusterId: this.clusterId,
            active: this.active
        });

        if (this.editedValue) {
            this.name.patchValue(this.editedValue.cluster_name);
            this.clusterId.patchValue(this.editedValue.cluster_id);
            this.active.patchValue(this.editedValue.active);
        }

        this.subs.push(this.rootSandbox.clusterSaveData$.subscribe(res => {
            if (res && res.success) {
                // console.log(res);
                this.dialogRef.close(res);
            }
        }));
        this.subs.push(this.rootSandbox.clusterSaveMsg$.subscribe(res => {
            if (res) {
                // this.message.log(res);
            }
        }));
    }

    confirm(): void {
        if (this.form.valid) {
            const params: any = {
                cluster_name: this.name.value,
                active: this.active.value
            }

            if (this.clusterId.value) {
                params.cluster_id = this.clusterId.value
            }

            this.rootSandbox.doClusterSave(params);
        } else {
            // this.message.log('Form invalid');
        }
    }

    cancel(): void {
        this.dialogRef.close(false);
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}