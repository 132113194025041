import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as store from '../state.interface';
import * as actions from './banner.action';
import {
    addBannerLoading,
    addBannerLoaded,
    addBannerFail,
    addBannerData,
    addBannerErrMes,
    updateBannerLoading,
    updateBannerLoaded,
    updateBannerFail,
    updateBannerData,
    updateBannerErrMes,
    getBannerByIdLoading,
    getBannerByIdLoaded,
    getBannerByIdFail,
    getBannerByIdData,
    getBannerListLoading,
    getBannerListLoaded,
    getBannerListFail,
    getBannerListData,
    deleteBannerItemLoading,
    deleteBannerItemLoaded,
    deleteBannerItemFail,
    addBannerSliderLoading,
    addBannerSliderLoaded,
    addBannerSliderFail,
    addBannerSliderData,
    addBannerSliderErrMess,
    deleteBannerSliderLoading,
    deleteBannerSliderLoaded,
    deleteBannerSliderFail,
    getBannerSliderListLoading,
    getBannerSliderListLoaded,
    getBannerSliderListFail,
    getBannerSliderListData,
    updateBannerSliderLoading,
    updateBannerSliderLoaded,
    updateBannerSliderFail,
    updateBannerSliderData,
    getBannerSliderItemLoading,
    getBannerSliderItemLoaded,
    getBannerSliderItemFail,
    getBannerSliderItemData
} from './banner.selector';

@Injectable()
export class BannerSandbox {
    public addBannerLoading$ = this.appState$.select(addBannerLoading);
    public addBannerLoaded$ = this.appState$.select(addBannerLoaded);
    public addBannerFail$ = this.appState$.select(addBannerFail);
    public addBannerData$ = this.appState$.select(addBannerData);
    public addBannerErrMes$ = this.appState$.select(addBannerErrMes);

    public updateBannerLoading$ = this.appState$.select(updateBannerLoading);
    public updateBannerLoaded$ = this.appState$.select(updateBannerLoaded);
    public updateBannerFail$ = this.appState$.select(updateBannerFail);
    public updateBannerData$ = this.appState$.select(updateBannerData);
    public updateBannerErrMes$ = this.appState$.select(updateBannerErrMes);

    public getBannerByIdLoading$ = this.appState$.select(getBannerByIdLoading);
    public getBannerByIdLoaded$ = this.appState$.select(getBannerByIdLoaded);
    public getBannerByIdFail$ = this.appState$.select(getBannerByIdFail);
    public getBannerByIdData$ = this.appState$.select(getBannerByIdData);

    public getBannerListLoading$ = this.appState$.select(getBannerListLoading);
    public getBannerListLoaded$ = this.appState$.select(getBannerListLoaded);
    public getBannerListFail$ = this.appState$.select(getBannerListFail);
    public getBannerListData$ = this.appState$.select(getBannerListData);

    public deleteBannerItemLoading$ = this.appState$.select(deleteBannerItemLoading);
    public deleteBannerItemLoaded$ = this.appState$.select(deleteBannerItemLoaded);
    public deleteBannerItemFail$ = this.appState$.select(deleteBannerItemFail);

    public addBannerSliderLoading$ = this.appState$.select(addBannerSliderLoading);
    public addBannerSliderLoaded$ = this.appState$.select(addBannerSliderLoaded);
    public addBannerSliderFail$ = this.appState$.select(addBannerSliderFail);
    public addBannerSliderData$ = this.appState$.select(addBannerSliderData);
    public addBannerSliderErrMess$ = this.appState$.select(addBannerSliderErrMess);

    public deleteBannerSliderLoading$ = this.appState$.select(deleteBannerSliderLoading);
    public deleteBannerSliderLoaded$ = this.appState$.select(deleteBannerSliderLoaded);
    public deleteBannerSliderFail$ = this.appState$.select(deleteBannerSliderFail);

    public getBannerSliderListLoading$ = this.appState$.select(getBannerSliderListLoading);
    public getBannerSliderListLoaded$ = this.appState$.select(getBannerSliderListLoaded);
    public getBannerSliderListFail$ = this.appState$.select(getBannerSliderListFail);
    public getBannerSliderListData$ = this.appState$.select(getBannerSliderListData);

    public updateBannerSliderLoading$ = this.appState$.select(updateBannerSliderLoading);
    public updateBannerSliderLoaded$ = this.appState$.select(updateBannerSliderLoaded);
    public updateBannerSliderFail$ = this.appState$.select(updateBannerSliderFail);
    public updateBannerSliderData$ = this.appState$.select(updateBannerSliderData);

    public getBannerSliderItemLoading$ = this.appState$.select(getBannerSliderItemLoading);
    public getBannerSliderItemLoaded$ = this.appState$.select(getBannerSliderItemLoaded);
    public getBannerSliderItemFail$ = this.appState$.select(getBannerSliderItemFail);
    public getBannerSliderItemData$ = this.appState$.select(getBannerSliderItemData);

    constructor(protected appState$: Store<store.AppState>) { }

    doAddBannerItem(params: any): void {
        this.appState$.dispatch(new actions.AddBannerAction(params));
    }
    doClearAddBannerState(): void {
        this.appState$.dispatch(new actions.ClearAddBannerStateAction({}));
    }

    doUpdateBannerItem(id: string, params: any): void {
        this.appState$.dispatch(new actions.UpdateBannerAction({ id, params }));
    }
    doClearUpdateBannerItemState(): void {
        this.appState$.dispatch(new actions.ClearUpdateBannerItemStateAction({}));
    }

    doGetBannerById(id: string): void {
        this.appState$.dispatch(new actions.GetBannerItemAction(id));
    }

    doGetBannerList(params: any): void {
        this.appState$.dispatch(new actions.GetBannerListAction({ params }));
    }

    doDeleteBannerItem(id: string): void {
        this.appState$.dispatch(new actions.DeleteBannerItemAction(id));
    }
    doClearDeleteBannerItemState(): void {
        this.appState$.dispatch(new actions.ClearDeleteBannerItemStateAction({}));
    }

    doAddBannerSlider(params: any): void {
        this.appState$.dispatch(new actions.AddBannerSliderAction(params));
    }
    doClearAddBannerSliderState(): void {
        this.appState$.dispatch(new actions.ClearAddBannerSliderStateAction({}));
    }

    doDeleteBannerSliderItem(id: string): void {
        this.appState$.dispatch(new actions.DeleteBannerSliderAction(id));
    }

    doGetBannerSliderList(params: any): void {
        this.appState$.dispatch(new actions.GetBannerSliderItemAction(params));
    }

    doUpdateBannerSliderItem(id: string | any, params: any): void {
        this.appState$.dispatch(new actions.UpdateBannerSliderItemAction({ id, params }));
    }
    doClearUpdateBannerSliderItemState(): void {
        this.appState$.dispatch(new actions.ClearUpdateBannerSliderItemStateAction({}));
    }

    doGetBannerSliderItem(id: string | any): void {
        this.appState$.dispatch(new actions.GetBannerSliderItemAction(id));
    }
}

