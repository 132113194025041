import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppSettingService } from '../shared/app.settings';
import * as store from '../state.interface';
import * as accountActions from './account.action';
import {
    registerUserData,
    registerUserFail,
    registerUserLoaded,
    registerUserLoading,
    registerUserErrors,
    loginUserLoading,
    loginUserLoaded,
    loginUserFail,
    loginUserData,
    loginUserErrors,
    sendPasswordRecoveryKeyLoading,
    sendPasswordRecoveryKeyLoaded,
    sendPasswordRecoveryKeyFail,
    sendPasswordRecoveryKeyData,
    sendPasswordRecoveryKeyErrMessage,
    changePasswordLoading,
    changePasswordLoaded,
    changePasswordFail,
    changePasswordData,
    changePasswordErrMessage,
    lastLoginedUserInfo,
    currentUserToken,
    currentUserRole,
    doLogOutEmitted,
    getCurrentUserLoading,
    getCurrentUserLoaded,
    getCurrentUserFail,
    getCurrentUserData,
    // currentShopName
} from './account.selector';

@Injectable()
export class AccountSandbox {
    public registerUserLoading$ = this.appState$.select(registerUserLoading);
    public registerUserLoaded$ = this.appState$.select(registerUserLoaded);
    public registerUserFail$ = this.appState$.select(registerUserFail);
    public registerUserData$ = this.appState$.select(registerUserData);
    public registerUserErrors$ = this.appState$.select(registerUserErrors);

    public loginUserLoading$ = this.appState$.select(loginUserLoading);
    public loginUserLoaded$ = this.appState$.select(loginUserLoaded);
    public loginUserFail$ = this.appState$.select(loginUserFail);
    public loginUserData$ = this.appState$.select(loginUserData);
    public loginUserErrors$ = this.appState$.select(loginUserErrors);

    public sendPasswordRecoveryKeyLoading$ = this.appState$.select(sendPasswordRecoveryKeyLoading);
    public sendPasswordRecoveryKeyLoaded$ = this.appState$.select(sendPasswordRecoveryKeyLoaded);
    public sendPasswordRecoveryKeyFail$ = this.appState$.select(sendPasswordRecoveryKeyFail);
    public sendPasswordRecoveryKeyData$ = this.appState$.select(sendPasswordRecoveryKeyData);
    public sendPasswordRecoveryKeyErrMessage$ = this.appState$.select(sendPasswordRecoveryKeyErrMessage);

    public changePasswordLoading$ = this.appState$.select(changePasswordLoading);
    public changePasswordLoaded$ = this.appState$.select(changePasswordLoaded);
    public changePasswordFail$ = this.appState$.select(changePasswordFail);
    public changePasswordData$ = this.appState$.select(changePasswordData);
    public changePasswordErrMessage$ = this.appState$.select(changePasswordErrMessage);

    public lastLoginedUserInfo$ = this.appState$.select(lastLoginedUserInfo);
    public currentUserToken$ = this.appState$.select(currentUserToken);
    public currentUserRole$ = this.appState$.select(currentUserRole);

    public doLogOutEmitted$ = this.appState$.select(doLogOutEmitted);

    public getCurrentUserLoading$ = this.appState$.select(getCurrentUserLoading);
    public getCurrentUserLoaded$ = this.appState$.select(getCurrentUserLoaded);
    public getCurrentUserFail$ = this.appState$.select(getCurrentUserFail);
    public getCurrentUserData$ = this.appState$.select(getCurrentUserData);

    // public currentShopName$ = this.appState$.select(currentShopName);

    constructor(
        protected appState$: Store<store.AppState>,
        private appSettings: AppSettingService
    ) {
        this.subs();
    }

    doRegisterUser(params: any): void {
        this.appState$.dispatch(new accountActions.RegisterUserAction(params));
    }
    doClearRegisterState(): void {
        this.appState$.dispatch(new accountActions.ClearRegisterStateAction({}));
    }

    doLoginUser(params: any): void {
        this.appState$.dispatch(new accountActions.LoginUserAction(params));
    }
    doClearLoginState(): void {
        this.appState$.dispatch(new accountActions.ClearLoginStateAction({}));
    }

    doSendPasswordRecoveryKey(params: any): void {
        this.appState$.dispatch(new accountActions.SendPasswordRecoveryKeyAction(params));
    }
    doClearSendPasswordRecoveryKeyState(): void {
        this.appState$.dispatch(new accountActions.ClearSendRecoveryPasswordKeyStateAction({}));
    }

    doChangePassword(params: any): void {
        this.appState$.dispatch(new accountActions.ChangePasswordAction(params));
    }
    doClearChangePasswordState(): void {
        this.appState$.dispatch(new accountActions.ClearChangePasswordStateAction({}));
    }

    doLogout(): void {
        this.appState$.dispatch(new accountActions.DoLogoutAction({}));
        this.appSettings.clerCurrentUser();
    }
    doLogoutSuccess(): void {
        this.appState$.dispatch(new accountActions.DoLogoutSuccessAction({}));
    }

    doGetCurrentUser(): void {
        this.appState$.dispatch(new accountActions.GetCurrentUserAction({}));
    }
    doClearGetCurrentUserState(): void {
        this.appState$.dispatch(new accountActions.ClearGetCurrentUserStateAction({}));
    }

    private subs(): void {
        this.getCurrentUserData$.subscribe(res => {
            if (res && res.cluster_id) {
                this.appSettings.setClusterId(res.cluster_id);
            } else {
                this.appSettings.clearClusterId();
            }
        });
    }
}

