import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommonSandbox } from 'app/core/common/common.sandbox';
import { CategoryModel } from 'app/core/common/models/category.model';
import { PageInfoModel } from 'app/core/common/models/page-info.model';
import { ProductModel } from 'app/core/common/models/product.model';
import { extractHostname } from 'app/core/shared/helpers/strings.helper';
import { BrandModel } from 'app/core/shop/models/brand.model';
import { LanguageModel } from 'app/core/shop/models/language.model';
import { MenuModel } from 'app/core/shop/models/menu.model';
import { ShopSettingsModel } from 'app/core/shop/models/shop-settings.model';
import { ShopSandbox } from 'app/core/shop/shop.sandbox';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { CategorySelectDialogComponent } from '../category-select/category-select.dialog';
import { ProductSelectDialogComponent } from '../product-select/product-select.dialog';
import { PromtDialogComponent } from '../promt-dialog/promt.dialog';
import { TranslationFieldDialogComponent } from '../translation-field/translation-field.dialog';
import { UploadImageDialogComponent } from '../upload-image/upload-image.dialog';

@Component({
    templateUrl: './add-tree-item.component.html',
    styleUrls: ['./add-tree-item.component.scss']
})
export class AddTreeItemComponent implements OnInit, OnDestroy {
    IMAGES_FOR_MENU = environment.IMAGES_FOR_MENU;

    name: FormControl = new FormControl('', Validators.required);
    shopName: FormControl = new FormControl({ value: '', disabled: true }, Validators.required);
    itemType: FormControl = new FormControl('url', Validators.required);
    page: FormControl = new FormControl('', Validators.required);
    url: FormControl = new FormControl('', [Validators.required]);
    brand: FormControl = new FormControl('', Validators.required);

    langList = ['en', 'es'];
    translatesCollection: { [key: string]: string } = {};

    private subs: Subscription[] = [];
    pagesList: PageInfoModel[] = [];

    selectedProduct: ProductModel;
    selectedCategory: CategoryModel;
    selectedBrand: BrandModel;

    linkshop: string = null;
    useTranslations = false;

    brands: BrandModel[] = [];
    public apiUrl: string = environment.API_URL;
    image;

    constructor(
        private dialogRef: MatDialogRef<AddTreeItemComponent>,
        @Inject(MAT_DIALOG_DATA) public data: MenuModel,
        private dialog: MatDialog,
        private shopSandbox: ShopSandbox,
        private commonSandbox: CommonSandbox,
        private cd: ChangeDetectorRef,
        private translateService: TranslateService
    ) {
        if (this.data) {
            // console.log(this.data);
            this.name.patchValue(data.name);
            this.itemType.patchValue(data.type);
            if (data.translations && !!Object.keys(data.translations).length) {
                this.translatesCollection = data.translations;
                this.useTranslations = true;
            } else {
                this.generateTranslationsObjects();
            }
            if (this.data.itemImage && this.data.itemPath) {
                this.image = {
                    nameFile: this.data.itemImage,
                    path: this.data.itemPath
                }
                console.log(this.data, this.image)
            }
        }
    }

    ngOnInit(): void {
        this.subs.push(this.shopSandbox.currentShopName$.subscribe(shopName => {
            this.shopName.patchValue(shopName || '');

            if (shopName) {
                this.commonSandbox.doGetShopPages({
                    idShopname: shopName
                });
                this.shopSandbox.doGetBrandsList({
                    idShopname: shopName
                });
            }
        }));
        this.subs.push(this.commonSandbox.shopPagesData$.subscribe(res => {
            if (res) {
                this.pagesList = res;
                if (this.data && this.data.value && this.data.type === 'page') {
                    const item = this.pagesList.find(el => +el.id === +this.data.value);
                    if (item) {
                        this.page.patchValue(item);
                    }
                }
                this.cd.markForCheck();
            }
        }));
        this.subs.push(this.shopSandbox.getBrandsListData$.subscribe((res: BrandModel[]) => {
            this.brands = res ? res : [];

            this.pathEditedBrand();
            this.cd.markForCheck();
        }));

        if (this.data) {
            switch (this.data.type) {
                case 'page':
                    // this.page.patchValue(this.data.value);
                    break;
                case 'product':
                    // const params: any = {
                    //     lng: this.translateService.currentLang,
                    //     limit: '40',
                    //     offset: '0',
                    //     idShopname: this.shopName.value,
                    //     id: [this.data.value]
                    // };
                    this.commonSandbox.doGetProductItem({
                        lng: this.translateService.currentLang,
                        limit: '40',
                        offset: '0',
                        idShopname: this.shopName.value,
                        id: [this.data.value]
                    });
                    break;
                case 'category':
                    // const params: any = ;
                    this.commonSandbox.doGetCategoryItem({
                        lng: this.translateService.currentLang,
                        limit: '40',
                        offset: '0',
                        idShopname: this.shopName.value,
                        id: [this.data.value]
                    });
                    break;
                case 'url':
                    this.url.patchValue(this.data.value);
                    this.url.markAllAsTouched();
                    break;
                case 'brand':
                    this.pathEditedBrand();
                    break;
            }
        }

        this.subs.push(this.commonSandbox.getProductItemData$.subscribe(res => {
            if (res && this.data && res.id === this.data.value) {
                this.selectedProduct = res;
            }
        }));
        this.subs.push(this.commonSandbox.getCategoryItemData$.subscribe(res => {
            if (res && this.data && res.id === this.data.value) {
                this.selectedCategory = res;
            }
        }));

        this.page.valueChanges.subscribe(res => {
            const lan = this.translateService.currentLang;
            if (lan && res.title) {
                this.name.patchValue(res.title[lan] || '');
            }
        });
        this.subs.push(this.shopSandbox.getShopSettingsData$.subscribe((settings: ShopSettingsModel) => {
            const linkshop = settings && settings.linkshop ? settings.linkshop : null;
            this.linkshop = linkshop ? extractHostname(linkshop) : null;
        }));
    }

    uploadImage(): void {
        this.dialog.open(UploadImageDialogComponent).afterClosed().subscribe(res => {
            console.log(res);
            if (res?.path) {
                this.image = res;
                console.log('this.image', this.image);
            }
        });
    }

    removeImage(): void {
        this.image = '';
    }

    private pathEditedBrand(): void {
        if (this.data && this.data.type === 'brand' && this.brands && !!this.brands.length) {
            const isAvailabe = this.brands.find(el => '' + el.id_manufacturer === '' + this.data.value);
            if (isAvailabe) {
                this.brand.patchValue(isAvailabe);
                this.brand.markAllAsTouched();
            }
        }
    }

    private generateTranslationsObjects(): void {
        this.translatesCollection = {};
        // this.langList.map(lan => {
        //     if (lan) {
        //         this.translatesCollection[lan] = '';
        //     }
        // });
        // console.log(this.translatesCollection);
    }

    private linkValidation(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null =>
            this.linkshop && control.value?.toLowerCase().indexOf(this.linkshop) === -1 ? null : { invalidLink: true };
    }

    removeKey(key: string): void {
        if (key) {
            this.dialog.open(PromtDialogComponent).afterClosed().subscribe(res => {
                if (res) {
                    delete this.translatesCollection[key];
                    this.cd.markForCheck();
                }
            });
        }
    }

    editItem(key: string): void {
        this.dialog.open(TranslationFieldDialogComponent, {
            data: {
                lngKey: key,
                value: this.translatesCollection[key]
            }
        }).afterClosed().subscribe(res => {
            if (res && res.text) {
                this.translatesCollection[key] = res.text;
                this.cd.markForCheck();
            }
        });
    }

    objectKeys(obj: any): any {
        return Object.keys(obj);
    }

    addTranslation(): void {
        this.dialog.open(TranslationFieldDialogComponent).afterClosed().subscribe(res => {
            // console.log(res);
            if (res && res.text && res.lang) {
                const l: LanguageModel = new LanguageModel(res.lang);
                // this.langList[l.isocode] = res.text;
                this.translatesCollection[l.isocode] = res.text;

                // console.log(this.translatesCollection);
                this.cd.markForCheck();
            }
        });
    }

    Add(): void {
        const params: any = {
            name: this.name.value,
            type: this.itemType.value,
            translations: this.translatesCollection
        };
        switch (this.itemType.value) {
            case 'page':
                params.value = this.page.value.id;
                break;
            case 'product':
                params.value = this.selectedProduct.id;
                break;
            case 'category':
                params.value = this.selectedCategory.id;
                break;
            case 'url':
                params.value = this.url.value;
                break;
            case 'brand':
                params.value = this.brand.value && this.brand.value.id_manufacturer ? this.brand.value.id_manufacturer : '';
                break;
        }

        if (this.image && this.image?.path) {
            params.itemImage = this.image.nameFile;
            params.itemPath = this.image.path;
        }

        this.dialogRef.close(params);
    }

    selectCategory(): void {
        this.dialog.open(CategorySelectDialogComponent).afterClosed().subscribe(res => {
            if (res) {
                // console.log(res);
                this.selectedCategory = res;
                // if (!this.name.value) {
                this.name.patchValue(res.titlelang);
                // }
            }
        });
    }

    selectProduct(): void {
        this.dialog.open(ProductSelectDialogComponent).afterClosed().subscribe(res => {
            if (res) {
                // console.log(res);
                this.selectedProduct = res;
                // if (!this.name.value) {
                this.name.patchValue(res.titlelang);
                // }
            }
        });
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}

