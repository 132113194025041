<h1 mat-dialog-title>{{'Upload image' | translate}}</h1>
<div mat-dialog-content style="min-width: 360px;">
    <p style="display: flex; justify-content: space-between; width: 100%;">
        {{'Select image' | translate}}:

        <input type="file" accept='image/jpeg, image/png' style="display: none;" (change)="onFileChanged($event)"
            #fileInput>
        <button mat-raised-button color="primary" (click)="fileInput.click()">{{'Select' | translate}}</button>
    </p>
    <div>
        <i>{{'Accept *jpg, *png files' | translate}}</i>
    </div>
    <div>
        <i>{{'Max size 30 Mb' | translate}}</i>
    </div>
    <hr>
    <div *ngIf="file && file.name" class="imgPreview">
        <img [src]="imgURL" height="180" *ngIf="imgURL">
        <div class="resize-content">
            <p>
                {{'File name' | translate}}: <span>{{file.name}}</span>
            </p>
            <p>
                <mat-checkbox [(ngModel)]="enableResizing">{{'Resize image' | translate}}</mat-checkbox>
            </p>
            <form class="resize-content" [formGroup]="resizeForm" *ngIf="enableResizing">
                <mat-form-field>
                    <mat-label>{{'Width' | translate}}</mat-label>
                    <input type="number" matInput placeholder="Width" [formControl]="width">
                    <mat-error *ngIf="width.hasError('required')">
                        {{'Required' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'Height' | translate}}</mat-label>
                    <input type="number" matInput placeholder="Height" [formControl]="height">
                    <mat-error *ngIf="height.hasError('required')">
                        {{'Required' | translate}}
                    </mat-error>
                </mat-form-field>
            </form>
        </div>
    </div>

</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onUpload()">{{'Upload' | translate}}</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true">{{'Close' | translate}}</button>
</div>