import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeHelper } from 'app/core/shared/helpers/localize.helper';

@Pipe({
    name: 'multiLang',
    pure: false
})
export class MultiLangPipe implements PipeTransform {
    constructor(
        private translateService: TranslateService
    ) { }

    transform(value: any, ...args: any[]): string {
        return LocalizeHelper.localize(value, this.translateService.currentLang);
    }
}

