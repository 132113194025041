import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    templateUrl: './registerSuccess.dialog.html'
})
export class RegisterSuccessDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<RegisterSuccessDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}
}
