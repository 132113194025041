import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './promt.dialog.html',
    styleUrls: ['./promt.dialog.scss']
})
export class PromtDialogComponent {
    constructor(
        private dialogRef: MatDialogRef<PromtDialogComponent>,
    ) { }

    confirm(): void {
        this.dialogRef.close(true);
    }

    cancel(): void {
        this.dialogRef.close(false);
    }
}

