import { Map, Record } from 'immutable';

export interface BannerState extends Map<string, any> {
    addBannerLoading: boolean;
    addBannerLoaded: boolean;
    addBannerFail: boolean;
    addBannerData: any;
    addBannerErrMes: any;

    updateBannerLoading: boolean;
    updateBannerLoaded: boolean;
    updateBannerFail: boolean;
    updateBannerData: any;
    updateBannerErrMes: any;

    getBannerByIdLoading: boolean;
    getBannerByIdLoaded: boolean;
    getBannerByIdFail: boolean;
    getBannerByIdData: any;

    deleteBannerItemLoading: boolean;
    deleteBannerItemLoaded: boolean;
    deleteBannerItemFail: boolean;

    getBannerListLoading: boolean;
    getBannerListLoaded: boolean;
    getBannerListFail: boolean;
    getBannerListData: any;

    addBannerSliderLoading: boolean;
    addBannerSliderLoaded: boolean;
    addBannerSliderFail: boolean;
    addBannerSliderData: any;
    addBannerSliderErrMess: string;

    deleteBannerSliderLoading: boolean;
    deleteBannerSliderLoaded: boolean;
    deleteBannerSliderFail: boolean;

    getBannerSliderListLoading: boolean;
    getBannerSliderListLoaded: boolean;
    getBannerSliderListFail: boolean;
    getBannerSliderListData: any;

    updateBannerSliderLoading: boolean;
    updateBannerSliderLoaded: boolean;
    updateBannerSliderFail: boolean;
    updateBannerSliderData: any;

    getBannerSliderItemLoading: boolean;
    getBannerSliderItemLoaded: boolean;
    getBannerSliderItemFail: boolean;
    getBannerSliderItemData: any;
}

export const BannerRecord = Record({
    addBannerLoading: false,
    addBannerLoaded: false,
    addBannerFail: false,
    addBannerData: {},
    addBannerErrMes: {},

    updateBannerLoading: false,
    updateBannerLoaded: false,
    updateBannerFail: false,
    updateBannerData: {},
    updateBannerErrMes: {},

    getBannerByIdLoading: false,
    getBannerByIdLoaded: false,
    getBannerByIdFail: false,
    getBannerByIdData: {},

    deleteBannerItemLoading: false,
    deleteBannerItemLoaded: false,
    deleteBannerItemFail: false,

    getBannerListLoading: false,
    getBannerListLoaded: false,
    getBannerListFail: false,
    getBannerListData: null,

    addBannerSliderLoading: false,
    addBannerSliderLoaded: false,
    addBannerSliderFail: false,
    addBannerSliderData: null,
    addBannerSliderErrMess: null,

    deleteBannerSliderLoading: false,
    deleteBannerSliderLoaded: false,
    deleteBannerSliderFail: false,

    getBannerSliderListLoading: false,
    getBannerSliderListLoaded: false,
    getBannerSliderListFail: false,
    getBannerSliderListData: null,

    updateBannerSliderLoading: false,
    updateBannerSliderLoaded: false,
    updateBannerSliderFail: false,
    updateBannerSliderData: null,

    getBannerSliderItemLoading: false,
    getBannerSliderItemLoaded: false,
    getBannerSliderItemFail: false,
    getBannerSliderItemData: null,
});


