import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Api } from '../api';
import { AppSettingService } from '../shared/app.settings';
// import { AppSettingService } from '../services/app.settings';

@Injectable()
export class BannerService extends Api {
    // private apiUrl: string = this.getApiUrl() + this.getApiVersion();

    private url = '/api/dashboard/' + this.getApiVersion();

    constructor(
        private http: HttpClient,
        protected appSettings: AppSettingService
    ) {
        super();
    }

    public addBanner(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }

        return this.http.post(this.getApiUrl() + this.appSettings.clusterId + this.url + '/banner-create', params);
    }

    public updateBanner(id: string, params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }

        return this.http.put(this.getApiUrl() + this.appSettings.clusterId + this.url + '/banner-update/' + id, params);
    }

    public getBanner(id: string): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }

        return this.http.get(this.getApiUrl() + this.appSettings.clusterId + this.url + '/banner/' + id);
    }

    public deleteBanner(id: string): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }

        return this.http.delete(this.getApiUrl() + this.appSettings.clusterId + this.url + '/banner-delete/' + id);
    }

    public getBannerList(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }

        return this.http.get(this.getApiUrl() + this.appSettings.clusterId + this.url + '/banner-list', params);
    }

    public addBannerSlider(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }

        return this.http.post(this.getApiUrl() + this.appSettings.clusterId + this.url + '/itembanner-create', params);
    }

    public deleteBannerSlider(id: string): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }

        return this.http.delete(this.getApiUrl() + this.appSettings.clusterId + this.url + '/itembanner-delete/' + id);
    }

    public getBannerSliderList(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }

        return this.http.get(this.getApiUrl() + this.appSettings.clusterId + this.url + '/itembanner-list', params);
    }

    public updateBannerSliderItem(id: string, params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }

        return this.http.put(this.getApiUrl() + this.appSettings.clusterId + this.url + '/itembanner-update/' + id, params);
    }

    public getBannerSliderItem(id: string): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }

        return this.http.get(this.getApiUrl() + this.appSettings.clusterId + this.url + '/itembanner/' + id);
    }
}

