import { Component, Inject } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class ErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    templateUrl: './restorePassword.dialog.html'
})
export class RestorePasswordDialogComponent {
    public emailFormControl = new FormControl('', [
        Validators.required,
        Validators.email
    ]);
    matcher = new ErrorStateMatcher();

    constructor(
        private dialogRef: MatDialogRef<RestorePasswordDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) { }
}

