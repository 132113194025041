import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PromtDialogComponent } from './promt-dialog/promt.dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { AddTreeItemComponent } from './add-tree-item/add-tree-item.component';
import { CategorySelectDialogComponent } from './category-select/category-select.dialog';
import { ClusterCreateDialogComponent } from './cluster-create/cluster-create.dialog';
import { CreateBannerDialogComponent } from './create-banner/create-banner.component';
import { CreateUserDialogComponent } from './create-user/create-user.dialog';
import { ProductSelectDialogComponent } from './product-select/product-select.dialog';
import { RegisterSuccessDialogComponent } from './registerSuccess/registerSuccess.dialog';
import { SettingsShopDialogComponent } from './settings-shop/settings-shop.dialog';
import { SelectCustomerDialogComponent } from './select-customer/select-customer.dialog';
import { RestorePasswordDialogComponent } from './restorePassword/restorePassword.dialog';
// import { SliderEditorDialogComponent } from './slider-editor/slider-editor.dialog';
import { TemplateEditorDialogComponent } from './template-editor/template-editor.component';
import { TranslationFieldDialogComponent } from './translation-field/translation-field.dialog';
import { TranslationSelectionDialogComponent } from './translation-selection/translation-field.dialog';
import { UploadImageDialogComponent } from './upload-image/upload-image.dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatSelectModule } from '@angular/material/select';
import { FuseHighlightModule } from '@fuse/components/highlight';
import { MatTableModule } from '@angular/material/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { SnackService } from './snack.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MultiLangPipe } from './multi-lang.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox'
import { AddVideoDialogComponent } from './add-video-dialog/add-video-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatButtonToggleModule,
        MatDialogModule,
        MatDatepickerModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatMomentDateModule,
        MatSelectModule,
        FuseHighlightModule,
        MatTableModule,
        DragDropModule,
        MatCardModule,
        MatListModule,
        MatSnackBarModule,
        MatCheckboxModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MultiLangPipe
    ],
    declarations: [
        PromtDialogComponent,
        AddTreeItemComponent,
        CategorySelectDialogComponent,
        ClusterCreateDialogComponent,
        CreateBannerDialogComponent,
        CreateUserDialogComponent,
        ProductSelectDialogComponent,
        RegisterSuccessDialogComponent,
        RestorePasswordDialogComponent,
        SelectCustomerDialogComponent,
        SettingsShopDialogComponent,
        // SliderEditorDialogComponent,
        TemplateEditorDialogComponent,
        TranslationFieldDialogComponent,
        TranslationSelectionDialogComponent,
        UploadImageDialogComponent,
        MultiLangPipe,
        AddVideoDialogComponent
    ]
})
export class SharedModule {
}
