import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import * as actions from './account.action';
import { AccountService } from './account.service';

@Injectable()
export class AccountEffect {
    constructor(
        private actions$: Actions,
        private accountService: AccountService
    ) { }

    registerUser$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.REGISTER_USER),
        map((action: actions.RegisterUserAction) => action.payload),
        switchMap(state => {
            return this.accountService.registerUser(state).pipe(
                map(data => new actions.RegisterUserSuccessAction(data)),
                catchError(err => of(new actions.RegisterUserFailAction(err)))
            );
        })
    ));

    loginUser$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.LOGIN_USER),
        map((action: actions.LoginUserAction) => action.payload),
        switchMap(state => {
            return this.accountService.loginUser(state).pipe(
                map(data => new actions.LoginUserSuccessAction(data)),
                catchError(err => of(new actions.LoginUserFailAction(err)))
            );
        })
    ));

    sendPasswordRecoveryKey$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.SEND_PASSWORD_RECOVERY_KEY),
        map((action: actions.SendPasswordRecoveryKeyAction) => action.payload),
        switchMap(state => {
            return this.accountService.sendPasswordRecoveryKey(state).pipe(
                map(data => new actions.SendPasswordRecoveryKeySuccessAction(data)),
                catchError(err => of(new actions.SendPasswordRecoveryKeyFailAction(err)))
            );
        })
    ));

    changePassword$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.CHANGE_PASSWORD),
        map((action: actions.ChangePasswordAction) => action.payload),
        switchMap(state => {
            return this.accountService.changePassword(state).pipe(
                map(data => new actions.ChangePasswordSuccessAction(data)),
                catchError(err => of(new actions.ChangePasswordFailAction(err)))
            );
        })
    ));

    getCurrentUser$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_CURRENT_USER),
        map((action: actions.GetCurrentUserAction) => action.payload),
        switchMap(state => {
            return this.accountService.getCurrentUser().pipe(
                map(data => new actions.GetCurrentUserSuccessAction(data)),
                catchError(err => of(new actions.GetCurrentUserFailAction(err)))
            );
        })
    ));
}

