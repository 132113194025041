export enum USER_ROLES {
    user = 'user',
    admin = 'admin',
    root = 'root'
}

export enum ACCOUNT_VERSION {
    standart = 'standart',
    pro = 'pro'
}
