import { environment } from "environments/environment";

export class Api {
    constructor() { }

    protected getApiUrl(): string {
        return environment.API_URL;
    }

    protected getApiVersion(): string {
        return environment.API_VERSION;
    }

    protected getRootApi(): string {
        return environment.ROOT_API;
    }
}
