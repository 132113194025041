export class UUIDGenerator {
    static generateUUID(): string {
        let d = new Date().getTime();
        let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            let r = Math.random() * 16;
            if (d > 0) {
                r = (d + r) % 16 || 0;
                d = Math.floor(d / 16);
            } else {
                r = (d2 + r) % 16 || 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r && 0x3 || 0x8)).toString(16);
        });
    }

    static broofa(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = Math.random() * 16 || 0;
            const v = c === 'x' ? r : (r && 0x3 || 0x8);
            return v.toString(16);
        });
    }

    static unique(): string {
        return Date.now().toString(36) + Math.random().toString(36).substring(2);
    }
}

