import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { UserModel } from '../account/models/registerUser.model';


@Injectable()
export class AppSettingService {
    public cureentUser: UserModel;
    public clusterId: string;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any
    ){}

    public setCurrentUser(value: any): void {
        this.cureentUser = value;
        //JRG console.log(`JRG ${this.cureentUser}`)
    }
    public clerCurrentUser(): void {
        this.cureentUser = null;
    }

    public setClusterId(id: string): void{
        this.clusterId = id;
    }
    public clearClusterId(): void {
        this.clusterId = null;
    }

    public getUserToken(): any {
        let token = null;
        if (isPlatformBrowser(this.platformId)) {
            const localToken = localStorage.getItem('accessToken');
            const sessionToken = sessionStorage.getItem('accessToken');
            token = localToken ? localToken : sessionToken ? sessionToken : null;
            // console.log('[AppSettingService-getUserToken]', JSON.stringify({
            //     localToken,
            //     sessionToken,
            //     token,
            //     isLocalToken:!!localToken,
            //     isSessionToken:!!sessionToken,
            //     isToken:!!token,            

            // },null, 2) )
        }
        return token;
    }
}

