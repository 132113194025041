export class ErrorItemModel {
    public id: number;
    public action: string;
    public client_status: number;
    public data_error: string;
    public data_parameters: any;
    public entity: string;
    public route: string;
    public status: number;
    public status_action: string;

    public opened = false;

    constructor(res: any) {
        this.id = res.id;
        this.action = res.action || '';
        this.client_status = res.client_status || 0;
        this.data_error = res.data_error || '';
        this.data_parameters = res.data_parameters || {};
        this.entity = res.entity || '';
        this.route = res.route || '';
        this.status = res.status || '1';
        this.status_action = res.status_action || '';
    }
}

