import { UUIDGenerator } from "app/core/shared/helpers/uuid.generator";

export class MenuModel {
    public expanded = false;

    public id: string;
    public name: string;
    public type: string;
    public value: string;
    public position = 0;
    public children: MenuModel[] = [];
    public translations: any;

    public itemImage: string;
    public itemPath: string;

    constructor(res: any) {
        this.id = res && res.id && res.id !== 0 ? res.id : UUIDGenerator.unique();
        this.name = res && res.name ? res.name : '';
        this.type = res && res.type ? res.type : '';
        this.value = res && res.value ? res.value : '';
        this.position = res && res.position ? res.position : 0;
        this.translations = res && res.translations ? res.translations : null;

        this.itemImage = res && res.itemImage ? res.itemImage : '';
        this.itemPath = res && res.itemPath ? res.itemPath : '';

        this.children = res && res.children && !!res.children.length ?
            res.children.filter((el: any) => !!el.name).map((el: any) => new MenuModel(el)) : [];

        this.recursiveSort(this);
    }

    public changeName(name: string): void {
        this.name = name || '';
    }
    public changeValue(value: string): void {
        this.value = value || '';
    }
    public changeType(type: string): void {
        this.type = type || '';
    }

    public getCurrentMaxDepth(): number {
        if (!this.children || !this.children.length) {
            return 0;
        } else {
            // const d = node.children.map(el => this.getCurrentMaxDepth(node));
            // return 1;
            // console.log('start cycle');
            return this.dephRecursive(this, 0);
        }
    }

    private dephRecursive(node: MenuModel, curDeph = 0): number {
        if (!node.children || !node.children.length) {
            // console.log('nothing', node);
            return curDeph;
        } else {
            const childDephCollection = node.children.map(el => this.dephRecursive(el));
            // console.log('check child', childDephCollection);
            let min = 0;
            childDephCollection.forEach(el => {
                if (min < el) {
                    min = el;
                }
            });
            return curDeph + min;
        }
        // return curDeph;
    }

    public recursiveSort(item: MenuModel): void {
        if (item.children && !!item.children.length) {
            this.sortChildByPosition(item);
            this.reassignChildPositions(item);

            item.children.map(el => this.recursiveSort(el));
        }
    }

    public addChild(item: MenuModel): void {
        if (item) {
            if (this.children && this.children.length) {
                this.children.push(item);
            } else {
                this.children = [item];
            }
            this.reassignChildPositions(this);
        }
    }

    public removeChilByItem(item: MenuModel): void {
        if (item) {
            const itemIndex = this.children.indexOf(item);
            if (itemIndex >= 0) {
                this.children.splice(itemIndex, 1);
            }
            this.reassignChildPositions(this);
        }
    }
    public removeChilByIndex(index: number): void {
        if (index >= 0) {
            this.children.splice(index, 1);
        }
    }

    public sortChildByPosition(item: MenuModel): void {
        if (item.children && !!item.children.length) {
            item.children = item.children.sort((a, b) => a.position - b.position);
        }
    }

    public reassignChildPositions(item: MenuModel): void {
        if (item.children && !!item.children.length) {
            item.children = item.children.map((el, index) => {
                el.position = index;
                return el;
            });
        }
    }
}
