import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class SnackService {
    constructor(
        private snackBar: MatSnackBar
    ) { }

    public log(message: string, delay = 1500): void {
        this.snackBar.open(
            message, 'Ok',
            {
                horizontalPosition: 'end',
                verticalPosition: 'bottom',
                duration: delay,
            }
        );
    }
}

