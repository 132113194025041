import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as store from '../state.interface';
import * as commonActions from './common.action';
import {
    getPaymentListData,
    getPaymentListLoading,
    getPaymentListLoaded,
    getPaymentListFail,
    getCategoryListLoading,
    getCategoryListLoaded,
    getCategoryListFail,
    getCategoryListData,
    uploadFileLoading,
    uploadFileLoaded,
    uploadFileFail,
    uploadFileData,
    uploadFileErrMess,
    updatePositionLoading,
    updatePositionLoaded,
    updatePositionFail,
    shopPagesLoading,
    shopPagesLoaded,
    shopPagesFail,
    shopPagesData,
    getProductsListLoading,
    getProductsListLoaded,
    getProductsListFail,
    getProductsListData,
    getProductItemLoading,
    getProductItemLoaded,
    getProductItemFail,
    getProductItemData,
    getCategoryItemLoading,
    getCategoryItemLoaded,
    getCategoryItemFail,
    getCategoryItemData,
    addNotificationLoading,
    addNotificationLoaded,
    addNotificationFail,
    addNotificationData,
    getCustomersListLoading,
    getCustomersListLoaded,
    getCustomersListFail,
    getCustomersListData,
    getOrderStatusSettingsLoading,
    getOrderStatusSettingsLoaded,
    getOrderStatusSettingsFail,
    getOrderStatusSettingsData,
    setOrderStatusSettingsLoading,
    setOrderStatusSettingsLoaded,
    setOrderStatusSettingsFail,
    setOrderStatusSettingsData,
    getPrestashopProductsListData,
    getPrestashopProductsListFailData,
    getPrestashopProductsListLoading,
    videoUploadData,
    videoUploadFailData,
    videoUploadLoading,
} from './common.selector';

@Injectable()
export class CommonSandbox {
    // 
    public videoUploadData$ = this.appState$.select(videoUploadData);
    public videoUploadFailData$ = this.appState$.select(videoUploadFailData);
    public videoUploadLoading$ = this.appState$.select(videoUploadLoading);
    // 
    public getPrestashopProductsListData$ = this.appState$.select(getPrestashopProductsListData);
    public getPrestashopProductsListFailData$ = this.appState$.select(getPrestashopProductsListFailData);
    public getPrestashopProductsListLoading$ = this.appState$.select(getPrestashopProductsListLoading);
    // 
    public getPaymentListLoading$ = this.appState$.select(getPaymentListLoading);
    public getPaymentListLoaded$ = this.appState$.select(getPaymentListLoaded);
    public getPaymentListFail$ = this.appState$.select(getPaymentListFail);
    public getPaymentListData$ = this.appState$.select(getPaymentListData);

    public getCategoryListLoading$ = this.appState$.select(getCategoryListLoading);
    public getCategoryListLoaded$ = this.appState$.select(getCategoryListLoaded);
    public getCategoryListFail$ = this.appState$.select(getCategoryListFail);
    public getCategoryListData$ = this.appState$.select(getCategoryListData);

    public getCategoryItemLoading$ = this.appState$.select(getCategoryItemLoading);
    public getCategoryItemLoaded$ = this.appState$.select(getCategoryItemLoaded);
    public getCategoryItemFail$ = this.appState$.select(getCategoryItemFail);
    public getCategoryItemData$ = this.appState$.select(getCategoryItemData);

    public uploadFileLoading$ = this.appState$.select(uploadFileLoading);
    public uploadFileLoaded$ = this.appState$.select(uploadFileLoaded);
    public uploadFileFail$ = this.appState$.select(uploadFileFail);
    public uploadFileData$ = this.appState$.select(uploadFileData);
    public uploadFileErrMess$ = this.appState$.select(uploadFileErrMess);

    public updatePositionLoading$ = this.appState$.select(updatePositionLoading);
    public updatePositionLoaded$ = this.appState$.select(updatePositionLoaded);
    public updatePositionFail$ = this.appState$.select(updatePositionFail);

    public shopPagesLoading$ = this.appState$.select(shopPagesLoading);
    public shopPagesLoaded$ = this.appState$.select(shopPagesLoaded);
    public shopPagesFail$ = this.appState$.select(shopPagesFail);
    public shopPagesData$ = this.appState$.select(shopPagesData);

    public getProductsListLoading$ = this.appState$.select(getProductsListLoading);
    public getProductsListLoaded$ = this.appState$.select(getProductsListLoaded);
    public getProductsListFail$ = this.appState$.select(getProductsListFail);
    public getProductsListData$ = this.appState$.select(getProductsListData);

    public getProductItemLoading$ = this.appState$.select(getProductItemLoading);
    public getProductItemLoaded$ = this.appState$.select(getProductItemLoaded);
    public getProductItemFail$ = this.appState$.select(getProductItemFail);
    public getProductItemData$ = this.appState$.select(getProductItemData);

    public addNotificationLoading$ = this.appState$.select(addNotificationLoading);
    public addNotificationLoaded$ = this.appState$.select(addNotificationLoaded);
    public addNotificationFail$ = this.appState$.select(addNotificationFail);
    public addNotificationData$ = this.appState$.select(addNotificationData);

    public getCustomersListLoading$ = this.appState$.select(getCustomersListLoading);
    public getCustomersListLoaded$ = this.appState$.select(getCustomersListLoaded);
    public getCustomersListFail$ = this.appState$.select(getCustomersListFail);
    public getCustomersListData$ = this.appState$.select(getCustomersListData);

    public getOrderStatusSettingsLoading$ = this.appState$.select(getOrderStatusSettingsLoading);
    public getOrderStatusSettingsLoaded$ = this.appState$.select(getOrderStatusSettingsLoaded);
    public getOrderStatusSettingsFail$ = this.appState$.select(getOrderStatusSettingsFail);
    public getOrderStatusSettingsData$ = this.appState$.select(getOrderStatusSettingsData);

    public setOrderStatusSettingsLoading$ = this.appState$.select(setOrderStatusSettingsLoading);
    public setOrderStatusSettingsLoaded$ = this.appState$.select(setOrderStatusSettingsLoaded);
    public setOrderStatusSettingsFail$ = this.appState$.select(setOrderStatusSettingsFail);
    public setOrderStatusSettingsData$ = this.appState$.select(setOrderStatusSettingsData);

    constructor(protected appState$: Store<store.AppState>) { }

    public doGetPaymentList(): void {
        this.appState$.dispatch(new commonActions.GetPaymentListAction({}));
    }

    public doGetCategoryList(params: any): void {
        this.appState$.dispatch(new commonActions.GetCategoryListAction(params));
    }

    public doGetCategoryItem(params: any): void {
        this.appState$.dispatch(new commonActions.GetCategoryItemAction(params));
    }
    public doClearGetCategoryItemState(): void {
        this.appState$.dispatch(new commonActions.ClearGetCategoryItemStateAction({}));
    }

    public doUploadImageFile(file: File, params: any): void {
        this.appState$.dispatch(new commonActions.UploadFileAction({ file, params }));
    }
    public doClearUploadImageFileState(): void {
        this.appState$.dispatch(new commonActions.ClearUploadFileStateAction({}));
    }

    public doUpdatePosition(params: any): void {
        this.appState$.dispatch(new commonActions.UpdatePositionAction(params));
    }
    public doClearUpdatePositionState(): void {
        this.appState$.dispatch(new commonActions.ClearUpdatePositionStateAction({}));
    }

    public doGetShopPages(params: any): void {
        this.appState$.dispatch(new commonActions.GetShopPagesAction(params));
    }

    public doGetProductsList(params: any): void {
        this.appState$.dispatch(new commonActions.GetProductsListAction(params));
    }

    public doGetProductItem(id: any): void {
        this.appState$.dispatch(new commonActions.GetProductItemAction(id));
    }
    public doClearGetProductItemState(): void {
        this.appState$.dispatch(new commonActions.ClearGetProductItemStateAction({}));
    }

    public doAddNotification(params: any): void {
        this.appState$.dispatch(new commonActions.AddNotificationAction(params));
    }
    public doClearAddNotificationState(): void {
        this.appState$.dispatch(new commonActions.ClearAddNotificationStateAction({}));
    }

    public doGetCustomerList(params: any): void {
        this.appState$.dispatch(new commonActions.GetCustomersListAction(params));
    }
    public doClearGetCustomerListState(): void {
        this.appState$.dispatch(new commonActions.ClearGetCustomersListStateAction({}));
    }

    public doGetOrderStatusSettings(params: any): void {
        this.appState$.dispatch(new commonActions.GetOrderStatusSettingsAction(params));
    }
    public doClearGetOrderStatusSettingsState(): void {
        this.appState$.dispatch(new commonActions.ClearGetOrderStatusSettingsStateAction({}));
    }

    public doSetOrderStatusSettings(params: any): void {
        this.appState$.dispatch(new commonActions.SetOrderStatusSettingsAction(params));
    }
    public doClearSetOrderStatusSettingsState(): void {
        this.appState$.dispatch(new commonActions.ClearSetOrderStatusSettingsStateAction({}));
    }

    public doGetPrestashopProductsList(params: any): void {
        this.appState$.dispatch(new commonActions.GetPrestashopProductsListAction({ params: params }))
    }

    public doVideoUpload(params: any): void {
        this.appState$.dispatch(new commonActions.VideoUploadAction({ params: params }));
    }
    public doClearVideoUploadState(): void {
        this.appState$.dispatch(new commonActions.ClearVideoUploadStateAction({}));
    }
}
