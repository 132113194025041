import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RegisterUserAction } from '../account/account.action';
import * as store from '../state.interface';

import * as shopActions from './shop.actions';
import {
    addShopLoading,
    addShopLoaded,
    addShopFail,
    addShopData,
    getShopSettingsLoading,
    getShopSettingsLoaded,
    getShopSettingsFail,
    getShopSettingsData,
    currentShopName,
    getMenuItemLoading,
    getMenuItemLoaded,
    getMenuItemFail,
    getMenuItemData,
    updateMenuItemLoading,
    updateMenuItemLoaded,
    updateMenuItemFail,
    updateMenuItemData,
    getOrderStatusListLoading,
    getOrderStatusListLoaded,
    getOrderStatusListFail,
    getOrderStatusListData,
    getShopLangListLoading,
    getShopLangListLoaded,
    getShopLangListFail,
    getShopLangListData,
    getBrandsListLoading,
    getBrandsListLoaded,
    getBrandsListFail,
    getBrandsListData,
    createPaypalHookLoading,
    createPaypalHookLoaded,
    createPaypalHookFail,
    createPaypalHookData,
    createStripeHookLoading,
    createStripeHookLoaded,
    createStripeHookData,
    createStripeHookFail,
    getShopErrorListLoading,
    getShopErrorListLoaded,
    getShopErrorListFail,
    getShopErrorListData,
    updateErrorStatusLoading,
    updateErrorStatusLoaded,
    updateErrorStatusFail,
    updateErrorStatusData,

    
} from './shop.selector';

@Injectable()
export class ShopSandbox {
    public addShopLoading$ = this.appState$.select(addShopLoading);
    public addShopLoaded$ = this.appState$.select(addShopLoaded);
    public addShopFail$ = this.appState$.select(addShopFail);
    public addShopData$ = this.appState$.select(addShopData);

    public getShopSettingsLoading$ = this.appState$.select(getShopSettingsLoading);
    public getShopSettingsLoaded$ = this.appState$.select(getShopSettingsLoaded);
    public getShopSettingsFail$ = this.appState$.select(getShopSettingsFail);
    public getShopSettingsData$ = this.appState$.select(getShopSettingsData);

    public currentShopName$ = this.appState$.select(currentShopName);

    public getMenuItemLoading$ = this.appState$.select(getMenuItemLoading);
    public getMenuItemLoaded$ = this.appState$.select(getMenuItemLoaded);
    public getMenuItemFail$ = this.appState$.select(getMenuItemFail);
    public getMenuItemData$ = this.appState$.select(getMenuItemData);

    public updateMenuItemLoading$ = this.appState$.select(updateMenuItemLoading);
    public updateMenuItemLoaded$ = this.appState$.select(updateMenuItemLoaded);
    public updateMenuItemFail$ = this.appState$.select(updateMenuItemFail);
    public updateMenuItemData$ = this.appState$.select(updateMenuItemData);

    public getOrderStatusListLoading$ = this.appState$.select(getOrderStatusListLoading);
    public getOrderStatusListLoaded$ = this.appState$.select(getOrderStatusListLoaded);
    public getOrderStatusListFail$ = this.appState$.select(getOrderStatusListFail);
    public getOrderStatusListData$ = this.appState$.select(getOrderStatusListData);

    public getShopLangListLoading$ = this.appState$.select(getShopLangListLoading);
    public getShopLangListLoaded$ = this.appState$.select(getShopLangListLoaded);
    public getShopLangListFail$ = this.appState$.select(getShopLangListFail);
    public getShopLangListData$ = this.appState$.select(getShopLangListData);

    public getBrandsListLoading$ = this.appState$.select(getBrandsListLoading);
    public getBrandsListLoaded$ = this.appState$.select(getBrandsListLoaded);
    public getBrandsListFail$ = this.appState$.select(getBrandsListFail);
    public getBrandsListData$ = this.appState$.select(getBrandsListData);

    public createPaypalHookLoading$ = this.appState$.select(createPaypalHookLoading);
    public createPaypalHookLoaded$ = this.appState$.select(createPaypalHookLoaded);
    public createPaypalHookFail$ = this.appState$.select(createPaypalHookFail);
    public createPaypalHookData$ = this.appState$.select(createPaypalHookData);

    public createStripeHookLoading$ = this.appState$.select(createStripeHookLoading);
    public createStripeHookLoaded$ = this.appState$.select(createStripeHookLoaded);
    public createStripeHookFail$ = this.appState$.select(createStripeHookFail);
    public createStripeHookData$ = this.appState$.select(createStripeHookData);

    public getShopErrorListLoading$ = this.appState$.select(getShopErrorListLoading);
    public getShopErrorListLoaded$ = this.appState$.select(getShopErrorListLoaded);
    public getShopErrorListFail$ = this.appState$.select(getShopErrorListFail);
    public getShopErrorListData$ = this.appState$.select(getShopErrorListData);

    public updateErrorStatusLoading$ = this.appState$.select(updateErrorStatusLoading);
    public updateErrorStatusLoaded$ = this.appState$.select(updateErrorStatusLoaded);
    public updateErrorStatusFail$ = this.appState$.select(updateErrorStatusFail);
    public updateErrorStatusData$ = this.appState$.select(updateErrorStatusData);

    constructor(protected appState$: Store<store.AppState>) { }

    doAddShop(params: any): void {
        this.appState$.dispatch(new shopActions.AddShopAction(params));
    }
    doClearAddShopState(): void {
        this.appState$.dispatch(new shopActions.ClearAddShopStateAction({}));
    }

    doGetShopSettings(params: any): void {
        this.appState$.dispatch(new shopActions.GetShopSettingsAction({ params }));
    }
    doClearShopSettingsState(): void {
        this.appState$.dispatch(new shopActions.ClearGetShopSettingsStateAction({}));
    }

    doGetMenuItem(params: any): void {
        this.appState$.dispatch(new shopActions.GetMenuItemAction(params));
    }

    doUpdateMenuItem(params: any): void {
        this.appState$.dispatch(new shopActions.UpdateMenuItemAction(params));
    }
    doClearUpdateMenuItem(): void {
        this.appState$.dispatch(new shopActions.ClearUpdateMenuItemAction({}));
    }

    doGetOrderStatusList(params: any): void {
        this.appState$.dispatch(new shopActions.GetOrderStatusListAction(params));
    }
    doClearGetOrderStatusListState(): void {
        this.appState$.dispatch(new shopActions.ClearGetOrderStatusListStateAction({}));
    }

    doGetShopLangList(params: any): void {
        this.appState$.dispatch(new shopActions.GetShopLangListAction(params));
    }
    doClearGetShopLangListState(): void {
        this.appState$.dispatch(new shopActions.ClearGetShopLangListStateAction({}));
    }

    doGetBrandsList(params: any): void {
        this.appState$.dispatch(new shopActions.GetBrandsListAction(params));
    }
    doClearBrandsListState(): void {
        this.appState$.dispatch(new shopActions.ClearGetBrandsListStateAction({}));
    }

    doCreatePaypalHook(params: any): void {
        this.appState$.dispatch(new shopActions.CreatePaypalHookAction(params));
    }
    doClearCreatePaypalHookState(params: any): void {
        this.appState$.dispatch(new shopActions.ClearCreatePaypalHookStateAction(params));
    }

    doCreateStripeHook(params: any): void {
        this.appState$.dispatch(new shopActions.CreateStripeHookAction(params));
    }
    doClearCreateStripeHookState(params: any): void {
        this.appState$.dispatch(new shopActions.ClearCreateStripeHookStateAction(params));
    }

    doGetShopErrorList(params: any): void {
        this.appState$.dispatch(new shopActions.GetShopErrorListAction({params}));
    }
    doClearGetShopErrorListState(): void {
        this.appState$.dispatch(new shopActions.ClearGetShopErrorListStateAction({}));
    }

    doUpdateErrorState(params: any): void {
        this.appState$.dispatch(new shopActions.UpdateErrorStatusAction(params));
    }
    doClearUpdateErrorStateState(): void {
        this.appState$.dispatch(new shopActions.UpdateErrorStatusAction({}));
    }
}

